export function humanTimeDiff(date: Date, compareTo?: Date): string {
  compareTo = compareTo ?? new Date()
  const diffInSeconds = (compareTo.getTime() - date.getTime()) / 1000
  return diffInSeconds < 0 ? futureDiff(-diffInSeconds) : pastDiff(diffInSeconds)
}

function futureDiff(seconds: number): string {
  if (seconds < 60) {
    return `nu`
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `over ${minutes} minuten `
  }
  const hours = Math.floor(minutes / 60)
  if (hours === 1) {
    return `over een uur`
  }
  if (hours < 24) {
    return `over ${hours} uur`
  }
  const days = Math.floor(hours / 24)
  return days === 1 ? 'over 1 dag' : `over ${days} dagen`
}

function pastDiff(seconds: number): string {
  if (seconds < 60) {
    return `zojuist`
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `${minutes} minuten geleden`
  }
  const hours = Math.floor(minutes / 60)
  if (hours === 1) {
    return `1 uur geleden`
  }
  if (hours < 24) {
    return `${hours} uur geleden`
  }
  const days = Math.floor(hours / 24)
  return days === 1 ? '1 dag geleden' : `${days} dagen geleden`
}