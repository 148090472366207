import React, {FC} from "react";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faAdd, faFilter, faPen, faPersonDigging, faTrash, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {ContentContainer} from "../components/content/ContentContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {Callout} from "../components/content/Callout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../components/layout/ModalProvider";
import {EditTeamsModal} from "../modals/EditTeamsModal";
import {DeleteTeamsModal} from "../modals/DeleteTeamsModal";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../api/dto";
import {Button} from "../components/form/Button";
import {AddCustomerUserModal} from "../modals/AddCustomerUserModal";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {AddTeamUserModal} from "../modals/AddTeamUserModal";
import {useTenant} from "../tenant/TenantContext";

export const TeamsDetails: FC = () => {
  const {team} = useParams();
  const {getTeam, getAllStatuses, getAllPriorities, getCustomers, getAllUsersForTenant, getAllTicketsForTeam} = useApiCall();
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketStatuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: teamResource, loading: loadingTeam, reload: reloadTeam} = useFetchedResource((team: string) => getTeam(team))
  const {resource: ticketResource, loading: loadingTickets, reload: reloadTickets} = useFetchedResource((team: string) => getAllTicketsForTeam(team))
  const {tenant} = useTenant()

  useRefreshEffect(() => {
    if (team === undefined) {
      console.warn("Team_uuid is undefined")
      return
    }
    reloadStatuses(undefined)
    reloadPriorities(undefined)
    reloadUsers(undefined)
    reloadCustomers(undefined)
    reloadTeam(team)
    reloadTickets(team)
  })

  return (
    <ContentContainer>
      <PageIconHeader backButton={true} icon={faUserGroup}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
          {label: "Instellingen", href: `/ticketflows`},
        ]} currentPage={teamResource?.team_name!} />
        <PageHeader>{teamResource?.team_name}</PageHeader>
      </PageIconHeader>
      
      <Callout color={"warning"}>
        <div className={"flex items-center space-x-4"}>
          <FontAwesomeIcon icon={faPersonDigging} />
          <div>
            <p>Deze pagina is nog steeds constructie.</p>
            <p>Momenteel is het alleen mogelijk om teams te bewerken en verwijderen</p>
          </div>
        </div>
      </Callout>

      {teamResource &&
        <TeamInfo team={teamResource} />
      }

      {teamResource && team &&
        <TeamsUsers team={teamResource} users={teamResource?.users ?? []} reload={() => reloadTeam(team)} />
      }

      {ticketStatuses && ticketPriorities && customers && users && teamResource && team && <div className={"my-4"}>
        <TicketList tickets={ticketResource ?? []} reload={() => reloadTickets(team ,true)} statuses={ticketStatuses} priorities={ticketPriorities} customers={customers} users={users} team={teamResource} />
      </div>}

    </ContentContainer>
  );
}

const TeamInfo: FC<{team: Team}> = (props) => {
  const {tenant} = useParams()
  const {open: openCreateModal} = useModal({title: `Edit ${props.team.team_name}`, body: <EditTeamsModal team={props.team} tenant={tenant!} />, size: "md"})
  const {open: openDeleteModal} = useModal({title: `Delete ${props.team.team_name}?`, body: <DeleteTeamsModal team={props.team} tenant={tenant!} />, size: "md"})
  const {open: openAddModal} = useModal({title: 'Gebruiker toevoegen', body: <AddTeamUserModal team={props.team}/>, size: "md"})
  return <div className={"flex space-x-4 justify-end"}>
    <Button size={"sm"} type={"primary" } text={"Gebruiker toevoegen"} icon={faPlus} onClick={() => openAddModal()} />
    <Button size={"sm"} type={"primary"} text={"Edit team"} icon={faPen} onClick={() => openCreateModal()} />
    <Button size={"sm"} type={"danger"} text={"Delete team"} icon={faTrash} onClick={() => openDeleteModal()} />
  </div>
}

const TeamsUsers: FC<{ team: Team, users: User[], reload: () => void }> = (props) => {
  // const {open: openAddModal} = useModal({title: 'Gebruiker toevoegen', body: <AddTeamUserModal customer={props.team}/>, size: "md"})
  const filteredUsers = props.users.sort((a, b) => a.name.localeCompare(b.name))
  return <div className={"flex flex-col"}>
    <div className={"flex justify-end p-2"}>
      {/*<Button type={"primary"} size={"sm"} icon={faAdd} text={"Gebruiker toevoegen"} onClick={openAddModal}/>*/}
    </div>
    <SearchPaginateReload data={filteredUsers} placeholder={'Zoek op naam, e-mail of telefoonnummer'} filterProperties={['name', 'email', 'phone',]}
                          onReload={() => props.reload()} paginationEnabled={true} resultsBuilder={(filteredUsers) => {
      return <DataTable
        keyProperty={'id'}
        compact={true}
        data={filteredUsers}
        columns={[
          {
            header: 'Klant',
            property: 'name'
          },
          {
            header: 'Email',
            property: 'email'
          },
          {
            header: 'Telefoon Nr.',
            property: 'phone'
          },
        ]}
        placeholder={<>Dit team heeft nog geen toegekende gebruikers</>}
        onClickEdit={() => window.alert("Get jebaited")}

      />
    }}/>
  </div>
}

const TicketList: FC<{ tickets: Ticket[], reload: () => void, statuses: TicketStatus[], customers: Customer[], users: User[], priorities: TicketPriority[], team: Team}> = props => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const filteredTickets = props.tickets.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1).slice(0, 5);

  if (props.tickets.length === 0) {
    return <>
      <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Toegeweze tickets</p>
      <div className={"py-10 bg-white dark:bg-zinc-700 rounded flex items-center justify-center border border-slate-100 dark:border-zinc-600"}>
        <p>Dit team heeft nog geen toegekende tickets.</p>
      </div>
    </>
  }

  return <div>
    <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Toegeweze tickets</p>
    {filteredTickets.map((ticket, i) => <TicketListCard
      key={i}
      ticket={ticket}
      isFirst={i === 0}
      isLast={i===(filteredTickets.length - 1)}
      team={props.team}
      customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
      status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
      priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
      assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
    />)}
  </div>
}
