import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {faFloppyDisk, faGear, faInbox, faPaperclip, faPersonDigging, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {useTenant} from "../tenant/TenantContext";
import colors from "tailwindcss/colors";
import {Tenant} from "../api/dto";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {Card} from "../components/Card";
import {Input} from "../components/form/Input";
import {Button} from "../components/form/Button";
import {useNavigate} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PillSelect} from "../components/form/PillSelect";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";

export const TenantSettings: FC = () => {
  const {tenant} = useTenant();
  const {getTenant} = useApiCall()
  const {resource: tenantDetails, loading: loadTenant, reload: reloadTenant} = useFetchedResource((tenant: string) => getTenant());

  useEffect(() => {
    if (tenant !== null) {
      reloadTenant(tenant)
    }
  }, [tenant, reloadTenant])

  return <>
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faGear}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Tenant Instellingen"} />
        <PageHeader>Organisatie instellingen</PageHeader>
      </PageIconHeader>

      {tenantDetails &&
        <BrandSettings tenant={tenantDetails}/>
      }

      {tenantDetails &&
        <LogoSettings tenant={tenantDetails} />
      }

    </ContentContainer>
  </>
}

const BrandSettings:FC<{tenant: Tenant}> = (props) => {
  const {editTenant} = useApiCall()
  const [name, setName] = useState(props.tenant.name);
  const [selectedColor, setSelectedColor] = useState(props.tenant.color_palette)
  const [errors, setErrors] = useState<ErrorBag>({})

  const defaultColors = Object.keys(colors).filter(k => ![
    'inherit', 'current', 'transparent', 'black', 'white',
    'lightBlue', 'warmGray', 'trueGray', 'coolGray', 'blueGray', 'neutral'
  ].includes(k))

  const reload = useRefresh()
  const navigate = useNavigate()

  const saveSettings = useCallback( async () => {
    const deployment = await editTenant(name, selectedColor).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`/${props.tenant.slug}/settings`);
    navigate(0);
  }, [name, selectedColor, props.tenant])

  return <Card title={"Thema instellingen"}>
        <div className={"flex items-stretch space-x-8"}>
          <div className={"flex flex-col items-start space-y-4"}>
            <Input type={"text"} value={name} onChange={setName} label={"Bedrijfsnaam"} />
            <div className={"flex flex-col text-sm font-medium text-brand-900 dark:text-zinc-300 w-full"}>
              Kleurenschema
              <div className={"flex flex-wrap gap-2"}>
                {defaultColors.map((color,i) => {
                  // @ts-ignore
                  const colorPalette = colors[color] ?? colors.slate
                  return <label key={i} className={`flex items-center cursor-pointer border-2 rounded-lg py-1 px-2 ${selectedColor === color ? `border-brand-700` : 'border-slate-200 dark:border-zinc-500'}`}>
                    <input type="radio" name={"palette"} value={color} key={color} checked={selectedColor === color} onChange={() => setSelectedColor(color)} className={"hidden"} />
                    <div className={`h-4 w-4 rounded-sm mr-2`} style={{background: colorPalette[500]}}></div>
                    <span className={"text-black dark:text-zinc-300"}>{color.charAt(0).toUpperCase()}{color.slice(1)}</span>
                  </label>
                })}
              </div>
            </div>
            {(name != props.tenant.name || selectedColor != props.tenant.color_palette) &&
              <Button size={"sm"} type={"primary"} icon={faFloppyDisk} text={"Opslaan"} onClick={saveSettings} />
            }
          </div>
          <div className={"flex-1 hidden lg:block"}>
            <Preview name={name} color={selectedColor} tenant={props.tenant} />
          </div>
        </div>
  </Card>

}

const Preview: FC<{name: string, color: string, tenant: Tenant}> = props => {
  // @ts-ignore
  const colorPalette = colors[props.color] ?? colors.slate
  return <div className={"w-[384px] h-[216px] bg-slate-50 dark:bg-zinc-800 rounded border border-slate-200 dark:border-zinc-600 overflow-hidden"}>
    <div className={"h-[20px] w-[40px] flex flex-col justify-end items-start px-1 bg-white dark:bg-zinc-800 border rounded-tl border:white dark:border-zinc-600"}>
      {props.tenant.logo ? (<img className={`w-[15px] rounded`} alt={''} src={props.tenant.logo} />) : (<div className={`w-[15px] bg-slate-900 dark:bg-zinc-300 rounded`} />)}
    </div>
    <div className={"flex items-stretch h-full"}>
      <div className={"w-[40px] py-2 bg-white dark:bg-zinc-800 border-r border-slate-100 dark:border-zinc-600"}>
        <h1 className={"h-1 ml-[5px] text-[5px] mb-2"} style={{color: colorPalette[900]}}>Service</h1>
        <div className={"h-1 mx-[5px] mb-2 rounded"} style={{background: colorPalette[200]}}></div>
        <div className={"h-1 mx-[5px] mb-2 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
        <div className={"h-1 mx-[5px] mb-2 bg-slate-100 dark:bg-zinc-500 rounded"}></div>

        <h1 className={"h-1 ml-[5px] text-[5px] mb-2"} style={{color: colorPalette[900]}}>CRM</h1>
        <div className={"h-1 mx-[5px] mb-2 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
        <div className={"h-1 mx-[5px] mb-2 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
        <div className={"h-1 mx-[5px] mb-2 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
      </div>
      <div className={"flex-1 p-2"}>
        <div className={"w-[200px] mx-auto"}>
          <div className={"flex justify-between items-center mb-[10px]"}>
            <h1 className={"h-[10px] flex items-center text-[6px] font-bold"}>Jouw Tickets</h1>
            <button className={"text-white font-medium rounded text-[4px] px-[4px] py-[2px] flex items-center"}
                    style={{background: colorPalette[800]}}>Toevoegen
            </button>
          </div>
          <div className={"bg-white dark:bg-zinc-700 rounded border border-slate-100 dark:border-zinc-500"}>
            <div className={"flex items-center"} style={{background: colorPalette[200]}}>
              <div className={"h-1 w-2/5 mx-[5px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
              <div className={"h-1 w-1/5 ml-[30px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
            </div>
            <div className={"flex items-center"}>
              <div className={"h-1 w-2/5 mx-[5px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
              <div className={"h-1 w-1/5 ml-[30px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
            </div>
            <div className={"flex items-center"}>
              <div className={"h-1 w-2/5 mx-[5px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
              <div className={"h-1 w-1/5 ml-[30px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
            </div>
            <div className={"flex items-center"}>
              <div className={"h-1 w-2/5 mx-[5px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
              <div className={"h-1 w-1/5 ml-[30px] my-1 bg-slate-100 dark:bg-zinc-500 rounded"}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const LogoSettings: FC<{ tenant: Tenant }> = (props) => {
  const {editTenantLogo, getTenantLogoURL} = useApiCall()
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [logoLink, setLogoLink] = useState<string|undefined>(undefined)
  const [errors, setErrors] = useState<ErrorBag>({})


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setSelectedFile(file || undefined);
  };

  const removeSelectedFile = () => {
    setSelectedFile(undefined);
  };

  const reload = useRefresh()
  const navigate = useNavigate()

  const saveLogo = useCallback( async () => {
    const deployment = await editTenantLogo(selectedFile).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`/${props.tenant.slug}/settings`);
    navigate(0);
  }, [selectedFile, props.tenant])

  return (
    <Card title={'Logo instellingen'}>
      <div className={'flex items-stretch space-x-8'}>
        <div className={'flex flex-col items-start space-y-4'}>
          {props.tenant.logo ? (
            <img className={`h-40 w-40 col-span-2 bg-slate-300 rounded`} alt={''} src={getTenantLogoURL(props.tenant.slug)} />) : (<div className={`h-40 w-40 col-span-2 bg-slate-300 rounded`} />)}

            <div>
              {selectedFile != null && (
                <button type={'button'}
                        className={'my-2 px-2 py-1 border border-slate-300 rounded text-xs flex items-center justify-between space-x-2 mr-2 mt-2 hover:bg-slate-50 hover:text-red-800'}
                        onClick={removeSelectedFile}><span>{selectedFile.name}</span><FontAwesomeIcon icon={faTimes}/>
                </button>
              )}

              <div className={"flex space-x-2"}>
                {selectedFile != null &&
                  <Button size={"sm"} type={"primary"} icon={faFloppyDisk} text={"Opslaan"}  onClick={saveLogo}/>
                }

                <Button type={'secondary'} size={'sm'} text={'Logo toevoegen'} icon={faPlus}
                        onClick={() => fileInputRef.current?.click()}/>
                <input type={'file'} ref={fileInputRef} onChange={handleFileChange} className={'hidden'}
                       accept={'image/*'} multiple={false}/>
              </div>
            </div>
        </div>
      </div>
    </Card>
  );
};
