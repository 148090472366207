import React, {FC, useCallback, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faFileContract, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {Input} from "../components/form/Input";
import {Button} from "../components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Sla, SlaRule, SlaRuleType, TicketPriority,} from "../api/dto";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {useFetchedResource} from "../api/APIContext";
import {useTenant} from "../tenant/TenantContext";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {useNavigate} from "react-router-dom";




export const SlaCreate: FC = () => {
  const {getAllPriorities} = useApiCall()

  const {resource: allPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())

  useRefreshEffect(() => {
    reloadPriorities(undefined)
  })

  return <>
    <ContentContainer size={'xl'}>
      <PageIconHeader icon={faFileContract}>
        <Breadcrumbs crumbs={[]} currentPage={"SLA aanmaken"} />
        <PageHeader>SLA aanmaken</PageHeader>
      </PageIconHeader>

      {allPriorities && <CreateForm priorities={allPriorities} />}
    </ContentContainer>
  </>
}

interface SlaRuleItem {
  ruleType: SlaRuleType
  in_office_values: {
    [priorityId: string]: string
  }
  out_office_values: {
    [priorityId: string]: string
  }
}

const CreateForm: FC<{priorities: TicketPriority[]}> = (props) => {
  const {addSla} = useApiCall()
  const {tenant} = useTenant()
  const [slaName, setSlaName] = useState('')
  const [slaRuleItems, setSlaRuleItems] = useState<SlaRuleItem[]>([
    {ruleType: SlaRuleType.maxResponse,
      in_office_values: {},
      out_office_values: {}}
  ])

  const reload = useRefresh()
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorBag>({})
  const save = useCallback( async () => {
    const deployment = await addSla(slaName, slaRuleItems).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`/${tenant}/sla`)
  }, [slaName, slaRuleItems])

  return <div className={"bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-600 rounded px-4 py-4 space-y-4"}>
    <form className={'flex flex-col'}>
      <div className={'my-2'}>
        <Input type={'text'} label={'Naam'} placeholder={'SLA naam'} value={slaName} onChange={(s) => setSlaName(s)}/>
      </div>

      <hr className={"my-4"}/>

      <SlaRuleList priorities={props.priorities} slaRuleItems={slaRuleItems} setSlaRuleItems={setSlaRuleItems}/>
    </form>

    <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={save}/>
  </div>
}

const SlaRuleList: FC<{priorities: TicketPriority[], slaRuleItems: SlaRuleItem[], setSlaRuleItems: React.Dispatch<React.SetStateAction<SlaRuleItem[]>>}> = (props) => {
  const setFieldState = (index: number, field: 'in_office_values'|'out_office_values', priorityIndex: string, value: string) => {
    props.setSlaRuleItems(old => old.map((oldEntry, k) => {
      if (k !== index) {
        return oldEntry
      }
      return {
        ...oldEntry,
        [field]: {
          ...oldEntry[field],
          [priorityIndex]: value,
        },
      }
    }))
  }

  function getSlaTypeName(slaType: SlaRuleType) {
    return {
      [SlaRuleType.maxResponse]: 'Max response tijd (min.)',
      [SlaRuleType.maxSolve]: 'Max oplostijd (min.)',
      [SlaRuleType.uptime]: 'Maandelijkse uptime (%)',
      [SlaRuleType.supportHours]: 'Prepaid support uren',
    }[slaType] ?? slaType
  }

  const addNewItem = () => {
    const newItem = {
      ruleType: SlaRuleType.maxResponse, // or any default value
      in_office_values: {},
      out_office_values: {}
    };
    props.setSlaRuleItems((prevSlaRuleItem) => [...prevSlaRuleItem, newItem]);
  };

  const removeItem = (index: number) => {
    props.setSlaRuleItems((prevSlaRuleItem) => prevSlaRuleItem.filter((_, i) => i !== index));
  }

  const handleRuleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const newRuleType = e.target.value as SlaRuleType;
    if (!Object.values(SlaRuleType).includes(newRuleType)) {
      console.error(`Invalid rule type: ${newRuleType}`);
      return;
    }
    props.setSlaRuleItems((prevSlaRuleItem) => {
      const updatedSlaRuleItem = [...prevSlaRuleItem];
      updatedSlaRuleItem[index] = {
        ...updatedSlaRuleItem[index],
        ruleType: newRuleType
      };
      return updatedSlaRuleItem;
    });
  };

  return <>
    {props.slaRuleItems.map((state, i) => {
      {/* sla list item */}
      return <div className={"flex items-center border border-slate-200 dark:border-zinc-500 px-3 py-2 rounded my-2"}>
        {/* sla rule */}
        <div className={'flex-1'}>
          <div className={'flex flex-row space-x-4 pb-2'}>
            <select
              className={"min-w-56 border-2 border-slate-200 dark:border-zinc-500 outline-brand-700 text-black dark:text-zinc-300 dark:bg-zinc-600 rounded text-base font-normal px-2 mt-1 h-10"}
              value={props.slaRuleItems[i].ruleType}
              onChange={(e) => handleRuleTypeChange(e, i)}
            >
              {[SlaRuleType.maxResponse, SlaRuleType.maxSolve, SlaRuleType.supportHours, SlaRuleType.uptime].map((slaType) => (
                <option key={slaType} value={slaType}>
                  {getSlaTypeName(slaType)}
                </option>
              ))}
            </select>
            <h1 className={'basis-2/6'}>Binnen kantooruren</h1>
            <h1 className={'basis-2/6 ml-4'}>Buiten kantooruren</h1>
          </div>

          {/* Priority loop */}
          {props.priorities.map((priority, j) => {
            return <div className={'flex flex-row space-x-4'} key={j}>
              <h1 className={'flex min-w-56 items-center'}>{priority.priority}</h1>
              <div className={'flex flex-row basis-2/6'}>
                <Input type={'text'} label={''} placeholder={'e.g. tijd in minuten of waarde'} value={props.slaRuleItems[i]?.in_office_values?.[priority.id] ?? ''}
                       onChange={(e) => setFieldState(i, 'in_office_values', priority.id, e)}/>
              </div>
              <div className={'flex flex-row basis-2/6 ml-4 space-x-2'}>
                <Input type={'text'} placeholder={'e.g. tijd in minuten of waarde'} label={''} value={props.slaRuleItems[i]?.out_office_values?.[priority.id] ?? ''}
                       onChange={(e) => setFieldState(i, 'out_office_values', priority.id, e)}/>
              </div>
            </div>
          })}
        </div>

        {/*delete icon*/}
        <div
          className={'flex justify-center items-center min-h-8 min-w-8 m-auto text-red-500 hover:bg-brand-100 hover:dark:bg-zinc-600 border border-transparent hover:dark:border-brand-500 rounded'} onClick={() => removeItem(i)}>
          <FontAwesomeIcon icon={faTrash}/>
        </div>
      </div>
    })}


    {props.slaRuleItems.length < Object.keys(SlaRuleType).length &&
    <div className={'flex justify-center mt-8'}>
      <Button type={"primary"} size={"md"} text={'Regel toevoegen'} icon={faPlus} onClick={addNewItem}/>
    </div>
    }

  </>
}