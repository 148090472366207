import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {Customer, TicketPriority} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {InputErrors} from "../components/form/InputErrors";

export const AddTicketModal: FC<{priorities: TicketPriority[], customers: Customer[]}> = (props) => {
  const {addTicket, getCustomers} = useApiCall()
  const refactoredPriorities = props.priorities.map((item) => [item.id, item.priority])
  const refactoredCustomers = props.customers.map((item) => [item.id, item.name])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [priority, setPriority] = useState(props.priorities[0].id)
  const [customer, setCustomer] = useState('')
  // const [team, setTeam] = useState('') // not being used for now
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await addTicket({
      subject: title,
      description: description,
      priority_uuid: priority,
      customer_uuid: customer,
      }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [title, description, priority, customer])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"items-center"}>
        <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
        <InputErrors errors={errors} field={'subject'} />
      </div>
      <div className={"items-center"}>
        <Input label={"Beschrijving"} type={"textarea"} value={description} onChange={(v) => setDescription(v)}/>
        <InputErrors errors={errors} field={'description'} />
      </div>
      <div className={"flex space-x-2"}>
        <Select label={"Prioriteit"} options={Object.fromEntries(refactoredPriorities)} value={priority} onChange={(v) => setPriority(v)} />
        <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer} onChange={(v) => setCustomer(v)} />
      </div>
      <InputErrors errors={errors} field={'customer_uuid'} />
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}