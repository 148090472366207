import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Toggle} from "../components/form/Toggle";
import {Tag, TicketStatus} from "../api/dto";
import {useParams} from "react-router-dom";
import {Button} from "../components/form/Button";
import {faTriangleExclamation, faX} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DefaultColors} from "tailwindcss/types/generated/colors";
import colors from "tailwindcss/colors";

export const EditTagModal: FC<{tag: Tag}> = (props) => {
  const [name, setName] = useState(props.tag.name)
  const [color, setColor] = useState(props.tag.color)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editTag} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editTag(
      props.tag.id, name, color as keyof DefaultColors).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [name, color])

  const defaultColors = Object.keys(colors).filter(k => ![
    'inherit', 'current', 'transparent', 'black', 'white',
    'lightBlue', 'warmGray', 'trueGray', 'coolGray', 'blueGray', 'neutral'
  ].includes(k))

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <Input label={"Naam"} type={"text"} value={name} onChange={setName}/>
      <div className={"flex flex-col text-sm font-medium text-slate-900 dark:text-zinc-300 w-full"}>
        Kleurenschema
        <div className={"flex flex-wrap gap-2 mt-1"}>
          {defaultColors.map((c, i) => {
            // @ts-ignore
            const colorPalette = colors[c] ?? colors.slate
            return <label key={i}
                          className={`flex items-center cursor-pointer border-2 rounded-lg py-1 px-2 ${c === color ? `border-brand-700` : 'border-slate-200 dark:border-zinc-500'}`}>
              <input type="radio" name={"palette"} value={c} key={i} checked={c === color}
                     onChange={() => setColor(c)} className={"hidden"}/>
              <div className={`h-4 w-4 rounded-sm mr-2`} style={{background: colorPalette[500]}}></div>
              <span className={"text-black dark:text-zinc-300"}>{c.charAt(0).toUpperCase()}{c.slice(1)}</span>
            </label>
          })}
        </div>
      </div>
      <InputErrors errors={errors} field={'name'}/>
      <InputErrors errors={errors} field={'color'}/>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}