import React, {FC, useEffect, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd, faCookieBite,
  faFilter,
  faInbox, faTicket,
} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {Button} from "../components/form/Button";
import {AddTicketModal} from "../modals/AddTicketModal";
import {useApiCall} from "../api/api";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../api/dto";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {SimplePaginate} from "../components/data/SimplePaginate";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchControlBar} from "../components/tickets/SearchControlBar";
import {useTenant} from "../tenant/TenantContext";

export const Tickets: FC = () => {
  const {getAllTicketsForTenant, getAllPriorities, getAllStatuses, getCustomers, getAllUsersForTenant, getTeams, getUserMe} = useApiCall()
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: ticketPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: user, reload: reloadUser} = useFetchedResource(() => getUserMe());
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {tenant} = useTenant()

  useEffect(() => {
    reloadUser(undefined)
  }, [])

  useRefreshEffect(() => {
    reloadTickets(undefined)
    reloadPriorities(undefined)
    reloadStatuses(undefined)
    reloadCustomers(undefined)
    reloadUsers(undefined)
    reloadUser(undefined)
    reloadTeams(undefined)
  })
  const {open: openCreateModal} = useModal({title: 'Ticket toevoegen', body: <AddTicketModal priorities={ticketPriorities ?? []} customers={customers ?? []} />})
  return (
    <ContentContainer size={'xl'}>
      <div className={"flex justify-between items-end"}>
        <PageIconHeader icon={faInbox}>
          <Breadcrumbs crumbs={[
            {label: "Servicedesk", href: `/${tenant}/ticket`},
          ]} currentPage={"Tickets"} />
          <PageHeader>Tickets</PageHeader>
        </PageIconHeader>
        <div className={"flex space-x-4 mb-8"}>
          <Button size={"md"} type={"primary"} text={"Ticket toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
        </div>
      </div>

      {loadTickets ? <>
        Loading tickets...
      </> : <>
        {ticketStatuses && ticketPriorities && customers && users && user && teams && <>
          <TicketList tickets={tickets ?? []} reload={() => reloadTickets(undefined, true)} statuses={ticketStatuses} users={users} teams={teams} me={user} priorities={ticketPriorities} customers={customers} customer={customers}/>
        </>}
      </>}

    </ContentContainer>
  );
}

const TicketList: FC<{
  tickets: Ticket[],
  reload: () => void,
  statuses: TicketStatus[],
  priorities: TicketPriority[],
  customers: Customer[],
  users: User[],
  teams: Team[],
  me: User,
  customer: Customer[]
}> = props => {
  const {tenant} = useTenant()
  const [searchedTickets, setSearchedTickets] = useState<Ticket[]>([])
  // Calculate the amount of open tickets, so it can be displayed in the placeholder when there are no search results.
  const openStatuses = props.statuses.filter(status => !status.isClosed).map(s => s.id)
  const openTickets = props.tickets.filter(ticket => openStatuses.includes(ticket.status_id)).length
  return <div>
    {tenant && <SearchControlBar
      tickets={props.tickets}
      statuses={props.statuses}
      priorities={props.priorities}
      customers={props.customers}
      users={props.users}
      teams={props.teams}
      me={props.me}
      tenantSlug={tenant}
      onSearchedTicketsChange={(tickets) => setSearchedTickets(tickets)}
    />}
    {searchedTickets.length === 0 && <div className={"border border-slate-200 dark:border-zinc-500 rounded h-64 flex flex-col items-center justify-center text-slate-600 dark:text-zinc-300 text-lg font-medium"}>
      <FontAwesomeIcon icon={openTickets > 0 ? faFilter : faCookieBite} className={'mb-8 text-4xl'} />
      {openTickets > 0 ? <>
        <h2>Er zijn nog {openTickets} open tickets die niet door je zoekopdracht of filter heen komen.</h2>
        <p className={"font-normal text-regular mt-4"}>Verbreed het filter/zoekopdracht om meer tickets te zien</p>
      </> : <>
        <h2>Alle tickets zijn opgelost!</h2>
        <p className={"font-normal text-regular mt-4"}>Geweldig!</p>
      </>}
    </div>}
    <SimplePaginate paginationLimit={20} data={searchedTickets} resultsBuilder={(paginatedTickets) => {
      return <>{paginatedTickets.map((ticket, i) => <TicketListCard
        key={i}
        ticket={ticket}
        isFirst={i===0}
        isLast={i===(paginatedTickets.length - 1)}
        team={props.teams.find(team => team.id === ticket.team_id) ?? null}
        customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
        status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
        priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
        assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
      />)}</>
    }}/>
  </div>
}
