import React, {FC, useEffect} from "react";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {Ticket, TicketAttachment, TicketNote, TicketReply} from "../api/dto";

interface PermissionContextType {
  // Ticket details
  canEditTicketDescription(ticket: Ticket): boolean,

  // Ticket notes
  canCreateTicketNote(): boolean,
  canEditTicketNote(ticketNote: TicketNote): boolean,

  // Ticket Replies
  canCreateTicketReply(): boolean,
  canEditTicketReply(ticketReply: TicketReply): boolean,

  // Ticket Attachments
  canDeleteAttachment(ticketAttachment: TicketAttachment): boolean,
}

export const PermissionContext = React.createContext<PermissionContextType>({} as PermissionContextType)

export const PermissionContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {getUserMe} = useApiCall();
  const {resource: user, reload: reloadUser} = useFetchedResource(() => getUserMe());

  useEffect(() => {
      reloadUser(undefined)
  }, [])

  const context: PermissionContextType = {
    // Ticket details
    canEditTicketDescription: (ticket) => ticket.user_id === user?.id,

    // Ticket notes
    canEditTicketNote: (ticketNote) => ticketNote.userId === user?.id,
    canCreateTicketNote: () => true, // Make dynamic based on user role, a customer cannot create notes.

    canEditTicketReply: (ticketReply) => ticketReply.userId === user?.id,
    canCreateTicketReply: () => true,

    canDeleteAttachment: (ticketAttachment)=> ticketAttachment.userId === user?.id,
  }
  return <PermissionContext.Provider value={context}>
    {children}
  </PermissionContext.Provider>
}


export function usePermission() {
  return React.useContext(PermissionContext)
}