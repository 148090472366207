import { ContentContainer } from "../components/content/ContentContainer";
import { PageIconHeader } from "../components/layout/PageIconHeader";
import {faArrowRight, faExclamationCircle, faShop} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumbs } from "../components/content/Breadcrumbs";
import { PageHeader } from "../components/content/PageHeader";
import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTenant } from "../tenant/TenantContext";
import { useApiCall } from "../api/api";
import { useFetchedResource } from "../api/APIContext";
import { useRefreshEffect } from "../components/RefreshController";
import {Integration} from "../api/dto";
import {Callout} from "../components/content/Callout";
import {Skeleton} from "../components/data/Skeleton";


export const MarketPlace: FC = () => {
  const { tenant } = useTenant();
  const { getIntegrations } = useApiCall();
  const { resource: integrationResource, loading: loadIntegrations, reload: reloadIntegrations } = useFetchedResource(getIntegrations);

  useRefreshEffect(() => {
    reloadIntegrations(undefined);
  });

  if (loadIntegrations) {
    return <ContentContainer size={"xl"}>
      <PageIconHeader icon={faShop}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Marketplace"} />
        <PageHeader>Marketplace</PageHeader>
      </PageIconHeader>
      <div className={"mt-8"}>
        <PageHeader>Beschikbare integraties</PageHeader>
        <div className={"grid grid-cols-3 gap-8"}>
          <Skeleton type={'card'}/>
          <Skeleton type={'card'}/>
          <Skeleton type={'card'}/>
        </div>
      </div>
    </ContentContainer>
  }

  if (!integrationResource) {
    return <Callout color={'danger'} icon={faExclamationCircle}>Er is iets verkeerd gegaan.</Callout>;
  }

  const enabledIntegrations = integrationResource.filter((integration) => integration.configured);
  const availableIntegrations = integrationResource.filter((integration) => !integration.configured);

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faShop}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Marketplace"} />
        <PageHeader>Marketplace</PageHeader>
      </PageIconHeader>

      {tenant && <ActivatedIntegrationList integrations={enabledIntegrations} tenant={tenant} />}
      {tenant && availableIntegrations.length > 0 && <AllIntegrationList integrations={availableIntegrations} tenant={tenant} />}

    </ContentContainer>
  );
};

const ActivatedIntegrationList: FC<{ integrations: Integration[], tenant: string }> = ({ integrations, tenant }) => {
  const navigate = useNavigate();
  return (
    <div className={"mt-8"}>
      <PageHeader>Geïnstalleerde integraties</PageHeader>
      <div className={"grid grid-cols-3 gap-8"}>
        {integrations.map((integration, i) => (
          <div key={i} className={"bg-white dark:bg-zinc-700 rounded-lg border border-slate-200 dark:border-zinc-600 overflow-hidden shadow-lg"} onClick={() => navigate(`/${tenant}/marketplace/integrations/${integration.integration}`)}>
            {integration.image ? <img src={integration.image} alt="" className={"h-20 w-20 rounded-lg border-4 border-white dark:border-zinc-700 mt-3 ml-4"} /> : <div className={"h-20 w-20 bg-blue-800 rounded-lg border-4 border-white mt-3 ml-4"} />}
            <div className={"h-28 bg-white dark:bg-zinc-700"}>
              <div className={"my-4 px-4"}>
                <h2 className={"text-2xl font-medium"}>{integration.name}</h2>
                <button className={"mt-4 h-12 rounded-lg w-full text-white font-medium bg-brand-500 "}>
                  Instellingen <FontAwesomeIcon className={"ml-2"} icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AllIntegrationList: FC<{ integrations: Integration[], tenant: string }> = ({ integrations , tenant}) => {
  const navigate = useNavigate();
  return (
    <div className={"mt-12"}>
      <PageHeader>Beschikbare integraties</PageHeader>
      <div className={"grid grid-cols-3 gap-8"}>
        {integrations.map((integration, i) => (
          <div key={i} className={"bg-white dark:bg-zinc-700 rounded-lg border border-slate-200 dark:border-zinc-600 overflow-hidden shadow-lg"} onClick={() => navigate(`/${tenant}/marketplace/integrations/${integration.integration}`)}>
            {integration.image ? <img src={integration.image} alt="" className={"h-20 w-20 rounded-lg border-4 border-white dark:border-zinc-700 mt-3 ml-4"} /> : <div className={"h-20 w-20 bg-blue-800 rounded-lg border-4 border-white mt-3 ml-4"} />}
            <div className={"h-28 bg-white dark:bg-zinc-700"}>
              <div className={"my-4 px-4"}>
                <h2 className={"text-2xl font-medium"}>{integration.name}</h2>
                <button className={"mt-4 h-12 rounded-lg w-full text-white font-medium bg-brand-500 "}>
                  Meer informatie <FontAwesomeIcon className={"ml-2"} icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};