import * as fa from "@fortawesome/free-solid-svg-icons";
import React, {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faCircleNotch} from "@fortawesome/free-solid-svg-icons";

interface ButtonProps {
  type: "primary"|"secondary"|"danger"|"ticket-table"
  size: "xs"|"sm"|"md"|"lg"
  text: string
  icon?: fa.IconDefinition
  submit?: true
  disabled?: boolean
  onClick?: () => void|Promise<void>
}
export const IconDropdownButton: FC<Omit<ButtonProps, "onClick">&{data: {[key: string]: string}, onClick: (key: string) => void|Promise<void>}> = props => {
  const height: string = {
    "xs": "h-6",
    "sm": "h-8",
    "md": "h-10",
    "lg": "h-16"
  }[props.size]
  const textStyle: string = {
    "xs": "px-2 text-xs font-medium",
    "sm": "px-1 py-1 text-sm font-medium",
    "md": "px-3 text-base font-medium",
    "lg": "px-3 text-lg",
  }[props.size]
  const iconStyle: string = {
    "xs": "mr-2",
    "sm": "px-1 w-4",
    "md": "px-3 w-4",
    "lg": "px-3 w-4",
  }[props.size]
  const type: string = {
    "primary": "bg-brand-900 dark:bg-brand-700 hover:bg-brand-800 text-white",
    "secondary": "bg-brand-50 dark:bg-brand-700 text-brand-800 dark:text-brand-200 hover:bg-brand-100 hover:dark:bg-brand-600",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
    "ticket-table": "bg-transparent",
  }[props.type]
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const save = async (key: string) => {
    setIsLoading(true)
    await props.onClick(key)
    setIsCollapsed(true)
    setIsLoading(false)
  }
  return <div className={`relative flex items-center border border-brand-400 dark:border-brand-500 justify-center ${textStyle} ${type} ${isCollapsed ? 'rounded' : 'rounded-t border-b-transparent shadow-lg'} hover:cursor-pointer`}
              onClick={() => setIsCollapsed(x => !x)}>
    <FontAwesomeIcon className={`mx-1 w-4`} icon={isLoading ? faCircleNotch : props.icon!} spin={isLoading}/>
    {props.text}
    <FontAwesomeIcon className={`${iconStyle} text-xs`} icon={isCollapsed ? faChevronDown : faChevronUp}/>

    {/* Dropdown */}
    {!isCollapsed && <div className={"fixed z-10 inset-0"} onClick={() => setIsCollapsed(false)}></div>}
    {!isCollapsed && <div
      className={"absolute min-w-[calc(100%+10px)] top-full left-0 bg-brand-100 dark:bg-brand-700 shadow-lg border border-brand-400 dark:border-brand-500 -mx-[1px] rounded-tr rounded-b z-20"} onClick={(e) => {
      setIsCollapsed(true)
    }}>
      {Object.entries(props.data).map(([key, value]) => {
        return <button key={key} className={"flex items-start my-1 min-w-full hover:bg-brand-200 hover:dark:bg-brand-600 px-2 py-1"}
                       onClick={() => save(key)}>
          <div className={`whitespace-nowrap ${value === props.text && 'opacity-50'}`}>{value}</div>
        </button>
      })}
    </div>
    }
  </div>
}