import {FC} from "react";
import {NavLink} from "react-router-dom";

interface BreadcrumbsProps {
  crumbs: {
    label: string
    href: string
  }[]
  currentPage: string
}
export const Breadcrumbs: FC<BreadcrumbsProps> = props => {
  return <div className={""}>
    <ul className={"h-6 -mt-4 -mb-2 flex items-center text-sm text-slate-500 dark:text-zinc-300"}>
      {props.crumbs.map((crumb, index) => {
        return <li key={index} className={"flex items-center"}>
          <NavLink to={crumb.href} className={"hover:text-slate-700 hover:dark:text-brand-400"}>{crumb.label}</NavLink>
          <span className={"mx-2"}>/</span>
        </li>
      })}
      <li>{props.currentPage}</li>
    </ul>
  </div>
}