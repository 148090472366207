import React, {FC, useCallback, useEffect, useState} from "react";
import {ModalFooter, useModal} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Ticket, TicketTime} from "../api/dto";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "../components/form/Button";
import {DeleteTicketTimeModal} from "./DeleteTicketTimeModal";
import {Input} from "../components/form/Input";
import {useKeycloak} from "@react-keycloak/web";

export const AddTicketTimeModal: FC<{ticket: Ticket}> = (props) => {
  const {addTicketTime} = useApiCall()
  const [date, setDate] = useState<Date>(new Date())
  const [time, setTime] = useState<number>(0.00)
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback( async () => {

    if (date.getUTCHours() === 0) {
      date.setHours(6)
    }

    const deployment = await addTicketTime(props.ticket.id, date, time, description).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [date, time, description])

  return <>
    <form onSubmit={() => save()} className={""}>
      <table className={"w-full"}>

        {/*Table headers*/}
        <thead className={""}>
          <tr className={"border-b border-slate-400"}>
            <th className={"text-left"}>Datum</th>
            <th className={"text-left"}>Uren</th>
            <th className={"text-left"}>Beschrijving</th>
            <th className={"text-left"}>Naam</th>
          </tr>
        </thead>


        {/*Table ticket time entries*/}
        <tbody>
          {props.ticket.times.map((item, i) => {
            return <tr key={i} className={"border-b border-slate-200"}>
              <td className={"py-1 px-1"}>{item.createdAt.toLocaleDateString('nl', {day: 'numeric', month: 'numeric', year: 'numeric'})}</td>
              <td className={"py-1 px-1"}>{item.value}</td>
              <td className={"py-1 px-1"}>{item.description}</td>
              <td className={"py-1 px-1"}>{item.user_name}</td>
              <td><DeleteTimeModal ticket={props.ticket} time={item} /></td>
            </tr>
            })}
          <tr>
            <td className={"px-1"}>
              <Input type={"date"} label={''} value={date} onChange={setDate}/>
            </td>
            <td className={"px-1"}>
              <Input type={"number"} label={''} value={time} min={0} step={0.25} onChange={setTime}/>
            </td>
            <td className={"px-1"}>
              <Input type={'text'} label={''} value={description} onChange={setDescription}/>
            </td>
            <td className={"px-1"}>
              <i>Namens jou</i>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}

const DeleteTimeModal: FC<{ticket: Ticket, time: TicketTime}> = props => {
  const {open: openDeleteModal} = useModal({title: 'Tijd verwijderen', body: <DeleteTicketTimeModal ticket={props.ticket} time={props.time}/>})
  return <IconButton icon={faTrash} type={"danger"} size={"xs"} onClick={openDeleteModal}/>
}