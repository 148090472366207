import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../components/form/Button";
import { Paragraph } from "../components/Paragraph";
import {PageHeader} from "../components/content/PageHeader";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";

export const NotFound: FC = () => {
  const navigate = useNavigate()

  return <div>
    <PageHeader>Not found</PageHeader>
    <Paragraph>
      This page either does not exist, or you do not have permission to view it.
    </Paragraph>
    <Button type={"primary"} size={"sm"} text={"Go back"} icon={faChevronCircleLeft} onClick={() => navigate(-1)} />
  </div>
}