import {FC} from "react";
import {ErrorBag} from "../../api/api";

export const InputErrors: FC<{errors: ErrorBag, field: string}> = props => {
  if (!(props.field in props.errors)) {
    return <></>
  }
  return <div>
    {props.errors[props.field].map((error, i) => {
      return <div key={i} className={"mt-2 text-red-600 dark:text-red-400"}>
        {error}
      </div>
    })}
  </div>
}