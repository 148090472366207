import {FC} from "react";

export const Card: FC<{children: React.ReactNode, title: string}> = ({children, title}) => {
  return <div className={"bg-white dark:bg-zinc-700 text-black dark:text-zinc-300 rounded my-8"}>
    <header className={"py-4 px-6 border-b border-gray-200 dark:border-zinc-500 font-medium"}>{title}</header>
    <div className={"py-4 px-6"}>
      {children}
    </div>
  </div>
}
export const CardDivider: FC = () => {
  return <div className={"border-b border-gray-200 dark:border-zinc-500 my-4 -mx-6"} />
}