import React, {FC, useCallback, useState} from "react";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../../../api/dto";
import {useModal} from "../../layout/ModalProvider";
import {AddTicketTimeModal} from "../../../modals/AddTicketTimeModal";
import {useApiCall} from "../../../api/api";
import {useRefresh} from "../../RefreshController";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {faCircleCheck, faFlag, faUser} from "@fortawesome/free-regular-svg-icons";
import {faClock, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../form/Button";

export const TicketButtons: FC<{ticket: Ticket, status: TicketStatus, priority: TicketPriority, allStatuses: TicketStatus[], allPriorities: TicketPriority[], customer?: Customer, tenantUsers: User[], tenantTeams: Team[]}> = (props) => {
  const {open: openAddModal} = useModal({title: 'Tijd toevoegen', body: <AddTicketTimeModal ticket={props.ticket}/>, size: "xl"})
  const refactoredStatuses = props.allStatuses.map((item) => [item.id, item.status]);
  const refactoredPriorities = props.allPriorities.map((item) => [item.id, item.priority])
  const refactoredTenantUsers = props.tenantUsers.map((item) => [item.id, item.name])
  const refactoredTenantTeams = props.tenantTeams.map((item) => [item.id, item.team_name])
  const [newStatus, setNewStatus] = useState(props.status.id)
  const [newPriority, setNewPriority] = useState(props.priority.id)
  const [newAssignee, setNewAssignee] = useState(props.ticket.assignee_id ? props.ticket.assignee_id : undefined)
  const [newTeam, setNewTeam] = useState(props.ticket.team_id ? props.ticket.team_id : undefined)

  const {editTicket} = useApiCall()
  const reload = useRefresh()

  let totalTimeWorked = 0;
  {props.ticket.times.map((time) => {
    totalTimeWorked = totalTimeWorked + Number(time.value);
  })}

  const saveNewValues = useCallback(async (status?: string, priority?: string, assignee?: string, team?: string) => {
    const deployment = await editTicket(
      props.ticket.id,
      {
        status_uuid: status,
        priority_uuid: priority,
        assignee_uuid: assignee,
        team_uuid: team,
      })
    reload()
  }, []);

  const selectedPriority = props.allPriorities.find(p => p.id === newPriority)!
  const selectedStatus = props.allStatuses.find(p => p.id === newStatus)!
  const selectedAssignee = props.tenantUsers.find(u => u.id === newAssignee)
  const selectedTeam = props.tenantTeams.find(t => t.id === newTeam)


  return <>
    <div
      className={"rounded border border-slate-300 dark:border-zinc-500 p-2 space-x-2 bg-white dark:bg-zinc-700 flex"}>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedPriority.priority} icon={faFlag}
                          data={Object.fromEntries(refactoredPriorities)} onClick={async (key) => {
        setNewPriority(key)
        await saveNewValues(newStatus, key, newAssignee, newTeam)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedStatus.status} icon={faCircleCheck}
                          data={Object.fromEntries(refactoredStatuses)} onClick={async (key) => {
        setNewStatus(key)
        await saveNewValues(key, newPriority, newAssignee, newTeam)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedAssignee?.name ?? '-'} icon={faUser}
                          data={Object.fromEntries(refactoredTenantUsers)} onClick={async (key) => {
        setNewAssignee(key)
        await saveNewValues(newStatus, newPriority, key, newTeam)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedTeam?.team_name ?? '-'} icon={faUserGroup}
                          data={Object.fromEntries(refactoredTenantTeams)} onClick={async (key) => {
        setNewTeam(key)
        await saveNewValues(newStatus, newPriority, newAssignee, key)
      }}/>
      <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
        <span className={"mr-1"}>Klant: </span>
        {/*// @ts-ignore*/}
        <span className={"font-medium"}>{props.customer ? props.customer.name : "??"}</span>
      </div>
      <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      {props.ticket.tasks.length > 0 && <>
        <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
          <span className={"mr-1"}>Taken: </span>
          <span className={`${props.ticket.tasks.filter(t => !t.is_done).length > 0 ? 'text-amber-700 dark:text-amber-300 font-bold' : 'font-medium'}`}>{props.ticket.tasks.filter(t => t.is_done).length} / {props.ticket.tasks.length}</span>
        </div>
        <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      </>}
      <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
        <span className={"mr-1"}>Uren: </span>
        <span className={"font-medium"}>{Math.round(totalTimeWorked * 100) / 100}</span>
      </div>
      <div className={"flex items-center"}>
        <Button type={'primary'} disabled={props.status.isClosed} size={'xs'} text={'Tijd schrijven'} icon={faClock}
                onClick={openAddModal}/>
      </div>
    </div>
    <div>
      <p
        className="text-xs italic text-slate-500 dark:text-zinc-200">{`Aangemaakt op ${props.ticket.createdAt.toLocaleDateString('nl', {
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      })} door ${props.ticket.user.name}`}</p>
    </div>
  </>
}