import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Toggle} from "../components/form/Toggle";
import {TicketStatus} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "../components/form/Button";
import {faTrash, faX} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";

export const DeleteStatusModal: FC<{status: TicketStatus, tenant: string}> = (props) => {
  const {deleteStatus} = useApiCall()
  const reload = useRefresh()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteStatus(props.status.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`${props.tenant}/ticketflow`)
  }

  return <>
    <p>
      <strong>Caution!</strong> You are about to delete <strong>{props.status.status}</strong> from your environment.
    </p>
    <InputErrors errors={errors} field={'error'} />
      <ModalFooter icon={faTrash} text={"delete"} onSubmit={confirm}/>
  </>
}