import React, {FC, useState} from "react";
import colors from "tailwindcss/colors";
import {generateDynamicColorSchemeCSS} from "../util/colorScheme";
export type AppColorPalette = string|{[tint: string]: string}

interface TenantContextType {
  tenant: string|null
  tenantChanged: (newTenant: string) => void
  setColorPalette: (newPalette: AppColorPalette) => void
}
export const TenantContext = React.createContext<TenantContextType>({} as TenantContextType)

export const TenantContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [colorPalette, setColorPalette] = useState<AppColorPalette>('gray')
  const [currentTenant, setCurrentTenant] = useState<string|null>(null)
  const context: TenantContextType = {
    tenant: currentTenant,
    setColorPalette: setColorPalette,
    tenantChanged: (newTenant) => {
      setCurrentTenant(newTenant);
    }
  }
  return <TenantContext.Provider value={context}>
    {/* @ts-ignore */}
    <style>{generateDynamicColorSchemeCSS(typeof colorPalette === "string" ? (colors[colorPalette] ?? colors.blue) : colorPalette)}</style>
    {children}
  </TenantContext.Provider>
}

export function useTenant() {
  return React.useContext(TenantContext)
}