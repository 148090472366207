import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Card} from "../components/Card";
import {Button} from "../components/form/Button";
import {PillSelect} from "../components/form/PillSelect";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {useNavigate} from "react-router-dom";
import {TenantChoice} from "./TenantChoice";

export const UserSettings: FC = () => {
  return <>
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={fa.faGear} backButton={true}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Gebruikers Instellingen"} />
        <PageHeader>Gebruikers Instellingen</PageHeader>
      </PageIconHeader>

      <LanguageSettings />

      <MfaCard />

    </ContentContainer>
  </>
}

const LanguageSettings: FC = props => {
  const [language, setLanguage] = useState('nl')
  const languages = {
    'nl': "Nederlands",
    'en': "Engels",
    'de': "Duits",
    'fr': "Frans",
  };

  return <Card title={"Taal instellingen"}>
    <PillSelect options={languages} value={language} onChange={(e) => setLanguage(e)} />
    <Button type={"secondary"} size={"sm"} icon={fa.faFloppyDisk} text={"Opslaan"} disabled={true} />
    <Callout color={"warning"} icon={fa.faPersonDigging}>
      <SectionHeader>
        Hier wordt nog aan gewerkt
      </SectionHeader>
      <p>Deze functionaliteit komt binnenkort beschikbaar</p>
    </Callout>
  </Card>
}


const MfaCard: FC = () => {
  const navigate = useNavigate();
  const {getMFAStatus, enableMFA} = useApiCall()
  let [state, setState] = useState<boolean | null>(null);
  const {resource: mfaStatus, reload: reloadMfaStatus} = useFetchedResource(() => getMFAStatus())

  useRefreshEffect(() => {
    reloadMfaStatus(undefined)
  })

  useEffect(() => {
    if (mfaStatus !== null && mfaStatus.item === false) {
      setState(false);
    } else {
      setState(true);
    }
  }, [mfaStatus]);


  const enableMfa = async () => {
    await enableMFA()
    navigate('/')
  };

  return (
    <Card title={"Multi Factor Authentication"}>
      <MFAStatus status={state} />
      {!state ? <div className={"my-2"}>
        <Button text={"Enable MFA"} type={'primary'} size={'sm'} onClick={enableMfa}/>
      </div> : <></>}
    </Card>
  )

}

const MFAStatus: FC<{status: boolean|null}> = props => {
  let loadingText;
  let mfaIcon;
  let iconBgColor;
  let iconTextColor;
  if (props.status === null) {
    loadingText = 'Verifying...'
    mfaIcon = fa.faQuestion;
    iconBgColor = "bg-gray-100 dark:bg-gray-500"
    iconTextColor = "text-gray-400 dark:text-gray-200"
  } else if (!props.status) {
    loadingText = 'MFA Not yet set up'
    mfaIcon = fa.faX;
    iconBgColor = "bg-red-100 dark:bg-red-700"
    iconTextColor = "text-red-400 dark:text-red-300"
  } else {
    loadingText = 'MFA Configured'
    mfaIcon = fa.faCheck;
    iconBgColor = "bg-emerald-100 dark:bg-emerald-700"
    iconTextColor = "text-emerald-500 dark:text-emerald-300"
  }
  return <div className={"flex items-center"}>
    <div className={`h-8 w-8 rounded-full ${iconBgColor} flex items-center justify-center`}>
      <FontAwesomeIcon icon={mfaIcon} className={`${iconTextColor}`}/>
    </div>
    <div className={"flex-1 mx-3"}>
      <h2 className={"text-slate-500 dark:text-zinc-300 text-sm"}>status: <span>{loadingText}</span></h2>
    </div>
  </div>
}