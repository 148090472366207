import React, {FC, ReactNode, useState} from "react";

interface TabContainerParams {
  tabs: {[name: string]: ReactNode}
  containerStyle?: string
  tabStyle?: (active: boolean) => string
}

export const TabContainer: FC<TabContainerParams> = ({ tabs, containerStyle, tabStyle }) => {
  const [selected, setSelected] = useState<string>(Object.keys(tabs)[0] ?? '_');

  if (Object.keys(tabs).length === 0) {
    return <></>
  }
  return <div>
    <nav className={containerStyle ?? 'rounded border border-slate-300 dark:border-zinc-500 p-2 space-x-2 bg-white dark:bg-zinc-700 flex mb-6'}>
      {Object.keys(tabs).map((t,i) => {
        const isActive = selected === t;
        return <button
          key={i}
          className={tabStyle ? tabStyle(isActive) : `px-4 h-8 text-sm ${isActive ? 'bg-brand-900 dark:bg-brand-700 hover:bg-brand-800 text-white' : 'bg-brand-50 dark:bg-brand-800 text-brand-800 dark:text-brand-200 hover:bg-brand-100 hover:dark:bg-brand-700'} rounded`}
          onClick={() => setSelected(t)}
        >
          {t}
        </button>;
      })}
    </nav>
    <main>
      {tabs[selected]}
    </main>
  </div>
}