import classNames from "classnames"
import React, { Key, ReactNode, useEffect, useRef } from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faPencil} from "@fortawesome/free-solid-svg-icons";

export type KeyProperties<T> = {
  [P in keyof T]: T[P] extends Key ? P : never;
}[keyof T]

export type PossibleColumns<T> = { [P in keyof T]: TableColumn<T, P> }[keyof T]

export interface BaseTableProps<T> {
  keyProperty: KeyProperties<T>
  data: T[]
  columns: Array<PossibleColumns<T>>

  placeholder: ReactNode

  /**
   * Optionally make rows clickable, this will add a chevron-right to the right
   * side of the table.
   */
  onClickRow?: (row: T) => void

  /**
   * Optionally make rows clickable, this will add an options button to the right
   * side of the table
   */
  onClickEdit?: (row: T) => void

  /**
   * Additional CSS classes to add to this component.
   */
  className?: string

  /**
   * An ID reference to an element describing the contents of this table.
   * Optional but recommended.
   */
  labelledBy?: string

  /**
   * Show a loading state while fetching content.
   */
  loading?: boolean
  // eslint-disable-next-line no-unused-vars
  headerMapper?: ( props: BaseTableProps<T> ) => JSX.Element[]
  compact?: boolean
}

export interface TableProps<T> extends BaseTableProps<T> {
  /**
   * Whether to add a tab index to the wrapper around this table when
   * horizontal scrolling is needed to see all its content. This should be set
   * to true for tables that don't have tab stops in their content, since
   * otherwise keyboard users will have no way of horizontally scrolling
   * through the content. If this is enabled, ensure a label for the wrapper
   * is also provided by setting the `labelledBy` prop.
   */
  enableScrollTabIndex?: boolean
}

export interface TableColumn<T, P extends keyof T> {
  property: P
  header: string
  wrap?: boolean
  // eslint-disable-next-line no-unused-vars
  transform?: ( property: T[P], row: T ) => ReactNode
}

export function DataTable<T> ( props: TableProps<T> ): JSX.Element {
  const container = React.createRef<HTMLDivElement>()
  const xScrollable = useRef<boolean>( false )
  useEffect( () => {
    xScrollable.current =
      props.enableScrollTabIndex === true &&
      container.current !== null &&
      container.current.scrollWidth > container.current.offsetWidth
  }, [props, container] )

  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return (
    <div
      ref={container}
      tabIndex={xScrollable.current ? 0 : undefined}
      role={xScrollable.current ? "group" : undefined}
      aria-labelledby={props.labelledBy}
      className={`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ${
        props.className || ""
      }`}
    >
      {renderTable( props, mapHeaders )}
    </div>
  )
}

export function renderEmptyState<T>( props: BaseTableProps<T> ): JSX.Element {
  return <div className={"h-32 flex items-center justify-center"}>
    {props.placeholder}
  </div>
}

export function renderTable<T> (
  props: BaseTableProps<T>,
  // eslint-disable-next-line no-unused-vars
  headerMapper: ( props: BaseTableProps<T> ) => JSX.Element[]
): JSX.Element {
  const isLoading = props.loading || false

  return (
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden border-2 border-slate-100 dark:border-zinc-600 sm:rounded-lg bg-white dark:bg-zinc-700">
        <table
          className="min-w-full"
          aria-labelledby={props.labelledBy}
        >
          <thead>
          <tr>{(props.headerMapper ?? headerMapper)( props )}</tr>
          </thead>
          <tbody>
          {isLoading ? renderLoadingState( props ) : mapRows( props )}
          </tbody>
        </table>
        {!isLoading && props.data.length === 0 && renderEmptyState( props )}
      </div>
    </div>
  )
}

function mapHeaders<T> ( props: TableProps<T> ): JSX.Element[] {
  const headers = props.columns.map( ( column ) =>
    <th
      key={column.header}
      className={`${props.compact ? 'py-2 px-3' : 'py-3 px-4'} text-left uppercase text-slate-500 dark:text-zinc-300 dark:bg-zinc-700 text-xs whitespace-no-wrap`}
    >
      {column.header}
    </th>
  )
  if (props.onClickRow) {
    headers.push(<th key={'_actions'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + " text-right"}></th>)
  }
  if (props.onClickEdit) {
    headers.push(<th key={'_actions'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + " text-right"}></th>)
  }
  return headers
}

function mapRows<T>(props: TableProps<T>): JSX.Element[] {
  return props.data.map((row, index) => {
    const key = row[props.keyProperty] as unknown as Key;
    return (
      // @ts-ignore
      <tr key={key} className={`${index !== 0 && ''} bg-white dark:bg-zinc-700 cursor-pointer hover:bg-brand-50 hover:dark:bg-zinc-600 border-t border-slate-50 dark:border-zinc-600`} onClick={props.onClickEdit ? (() => props.onClickEdit(row)) : props.onClickRow ? (() => props.onClickRow(row)) : undefined}>
        {mapCells(row, props)}
      </tr>
    );
  });
}


function mapCells<T> ( row: T, props: TableProps<T> ): JSX.Element[] {
  const columns = props.columns.map( ( column, index ) => {
    const spacing = props.compact ? 'py-2 px-3' : 'py-3 px-4'
    const cellClasses = classNames( {
      [`${spacing} text-left`]: true,
      "whitespace-no-wrap": column.wrap !== true,
      "font-medium ": index === 0,
      "": index !== 0,
    } )
    const cellContent = column.transform
      ? column.transform( row[column.property], row )
      : row[column.property]
    if ( index === 0 ) {
      return (
        <th key={column.header} className={cellClasses} scope="row">
          <>{cellContent}</>
        </th>
      )
    } else {
      return (
        <td key={column.header} className={cellClasses}>
          <>{cellContent}</>
        </td>
      )
    }
  } )
  if (props.onClickRow) {
    columns.push(<td key={'_action'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + ' text-brand-600'}>
      <div className={"flex items-center justify-end space-x-3"}>
        <>&nbsp;</><span className={"text-xs uppercase tracking-wide font-medium"}>Details</span> <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </td>)
  }
  if (props.onClickEdit) {
    columns.push(<td key={'_action'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + ' text-brand-600'}>
      <div className={"flex items-center justify-end space-x-3"}>
        <>&nbsp;</><span className={"text-xs uppercase tracking-wide font-medium"}>Bewerk</span> <FontAwesomeIcon icon={faPencil} />
      </div>
    </td>)
  }
  return columns
}

function renderLoadingState<T> ( props: TableProps<T> ): JSX.Element {
  return <>
    <tr className={`bg-white border-t border-slate-50`}>
      {props.columns.map( ( column, index ) => {
        return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
          <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
        </td>
      })}
    </tr>
    <tr className={`bg-white border-t border-slate-50`}>
      {props.columns.map( ( column, index ) => {
        return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
          <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
        </td>
      })}
    </tr>
    <tr className={`bg-white border-t border-slate-50`}>
    {props.columns.map( ( column, index ) => {
      return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
        <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
      </td>
    })}
  </tr>
    <tr className={`bg-white border-t border-slate-50`}>
    {props.columns.map( ( column, index ) => {
      return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
        <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
      </td>
    })}
  </tr>
  </>
}
