import React, {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faBuilding, faChevronRight, faFilter, faPencil, faUser} from "@fortawesome/free-solid-svg-icons";
import {useTenant} from "../tenant/TenantContext";
import {Button} from "../components/form/Button";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Customer, Deal} from "../api/dto";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {Skeleton} from "../components/data/Skeleton";
import {useModal} from "../components/layout/ModalProvider";
import {AddDealModal} from "../modals/AddDealModal";
import {Select} from "../components/form/Select";
import {usePersistentState} from "../util/usePersistentState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {humanTimeDiff} from "../util/humanTimeDiff";
import {NavLink} from "react-router-dom";

export const Deals: FC = () => {
  const {tenant} = useTenant()
  const {getDeals} = useApiCall()
  const {getCustomers} = useApiCall()
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: deals, reload: reloadDeals} = useFetchedResource(() => getDeals())

  const [filter, setFilter] = usePersistentState<string>('filter_deals_old', '30')

  useRefreshEffect(() => {
    reloadDeals(undefined)
    reloadCustomers(undefined)
  })
  const addDealModal = useModal({title: 'Relatie toevoegen', body: <AddDealModal  customers={customers!}/>, size: "md"})
  const filteredDeals = deals?.filter(d => {
    if (d.stage === 'won' || d.stage === 'lost') {
      return new Date(d.updatedAt).getTime() > Date.now() - parseInt(filter) * 24 * 60 * 60 * 1000
    }
    return true
  })
  return (
    <ContentContainer size={'2xl'}>
      <div className={"flex justify-between items-center"}>
        <PageIconHeader icon={faFilter}>
          <Breadcrumbs crumbs={[
            {label: "CRM", href: `/${tenant}/relations`},
          ]} currentPage={"Deals"} />
          <PageHeader>Deals</PageHeader>
        </PageIconHeader>
        <div className={"mb-8 flex items-center space-x-3"}>
          <Select inline label={'Filter gesloten deals'} options={{
            '14': '14 dagen',
            '30': '30 dagen',
            '90': '90 dagen',
            '365': '1 jaar',
          }} value={filter} onChange={setFilter} />
          <Button type={'primary'} size={'md'} text={'Nieuw'} icon={faPlus} disabled={!customers} onClick={() => addDealModal.open()}/>
        </div>
      </div>
      {filteredDeals ? <DealDashboard tenant={tenant ?? '?'} deals={filteredDeals} showClosed={true} /> : <Skeleton type={'card'} />}
    </ContentContainer>
  )
}

export const DealDashboard: FC<{deals: Deal[], tenant: string, showClosed: boolean}> = (props) => {

  return <div className={""}>
    <div className={"w-full min-h-96 space-x-4 hidden xl:flex"}>
      <DealColumn tenant={props.tenant} stage={'Nieuw'} deals={props.deals.filter(d => d.stage === 'new')} />
      <DealColumn tenant={props.tenant} stage={'Verkennen'} deals={props.deals.filter(d => d.stage === 'exploring')} />
      <DealColumn tenant={props.tenant} stage={'Onderhandelen'} deals={props.deals.filter(d => d.stage === 'negotiating')}/>
      <DealColumn tenant={props.tenant} stage={'Offerte'} deals={props.deals.filter(d => d.stage === 'quoting')} />
      {props.showClosed && <DealColumn tenant={props.tenant} stage={'Gewonnen / Verloren'} deals={props.deals.filter(d => ['won', 'lost'].includes(d.stage))}/>}
    </div>
    <div className={"w-full space-y-4 flex flex-col items-stretch xl:hidden"}>
      <DealRow tenant={props.tenant} stage={'Nieuw'} deals={props.deals.filter(d => d.stage === 'new')}  />
      <DealRow tenant={props.tenant} stage={'Verkennen'} deals={props.deals.filter(d => d.stage === 'exploring')} />
      <DealRow tenant={props.tenant} stage={'Onderhandelen'} deals={props.deals.filter(d => d.stage === 'negotiating')} />
      <DealRow tenant={props.tenant} stage={'Offerte'} deals={props.deals.filter(d => d.stage === 'quoting')} />
      {props.showClosed && <DealRow tenant={props.tenant} stage={'Gewonnen / Verloren'} deals={props.deals.filter(d => ['won', 'lost'].includes(d.stage))} />}
    </div>
  </div>
}

const DealColumn: FC<{ stage: string, deals: Deal[], tenant: string }> = (props) => {
  const total = props.deals.reduce((acc, deal) => acc + deal.amount, 0)
  return <div className={"flex-1 flex flex-col items-stretch border border-slate-200 dark:border-zinc-600 rounded bg-slate-100 dark:bg-zinc-800"}>
    <div className={"border-b border-slate-200 dark:border-zinc-600 px-4 py-3 font-medium bg-white dark:bg-zinc-700 rounded-t flex items-center justify-between"}>
      <span>{props.stage}</span>
      <span className={'font-mono text-sm font-bold text-slate-500 dark:text-zinc-300'}>€ {total.toLocaleString('nl', {
        currency: 'EUR',
        maximumFractionDigits: 0
      })}</span>
    </div>
    <div className={"flex-1"}>
      {props.deals.map((deal, i) => <DealCard tenant={props.tenant} deal={deal} key={i} />)}
    </div>
  </div>
}

const DealRow: FC<{stage: string, deals: Deal[], tenant: string}> = (props) => {
  const total = props.deals.reduce((acc, deal) => acc + deal.amount, 0)
  return <div className={"flex-1 flex flex-col min-h-48 items-stretch border border-slate-200 dark:border-zinc-600 rounded bg-slate-100 dark:bg-zinc-800"}>
    <div
      className={"border-b border-slate-200 dark:border-zinc-600 px-4 py-3 font-medium bg-white rounded-t flex items-center justify-between"}>
      <span>{props.stage}</span>
      <span className={'font-mono text-sm font-bold text-slate-500 dark:text-zinc-300'}>€ {total.toLocaleString('nl', {
        currency: 'EUR',
        maximumFractionDigits: 0
      })}</span>
    </div>
    <div className={"flex-1"}>
      {props.deals.map((deal, i) => <DealCard tenant={props.tenant} deal={deal} key={i}/>)}
    </div>
  </div>
}

const DealCard: FC<{ deal: Deal, tenant: string }> = (props) => {
  const styleDefault = 'bg-white dark:bg-zinc-600 text-slate-700 dark:text-zinc-100'
  const styleWon = 'bg-green-100 text-green-900 dark:bg-green-800 dark:text-green-100'
  const styleLost = 'bg-red-100 text-red-900 dark:bg-red-800 dark:text-red-100'
  return <div className={`m-2 ${props.deal.stage === 'won' ? styleWon : (props.deal.stage === 'lost' ? styleLost : styleDefault)} px-3 py-3 border border-slate-200 dark:border-zinc-500 rounded space-y-2`}>
    <div className={'flex items-center'}>
      <div className={"flex-1"}>
        <div className={"font-mono text-sm font-bold"}>€ {props.deal.amount.toLocaleString('nl', {
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</div>
        <div className={""}>{props.deal.name}</div>
      </div>
      <NavLink to={`/${props.tenant}/deals/${props.deal.id}`} className={`h-8 w-8 text-lg rounded-full ${props.deal.stage === 'won' ? 'bg-green-200 dark:bg-green-700 hover:bg-green-100 dark:hover:bg-green-700 text-green-600 dark:text-green-300' : (props.deal.stage === 'lost' ? 'bg-red-200 dark:bg-red-700 hover:bg-red-100 dark:hover:bg-red-700 text-red-600 dark:text-red-300' : 'bg-brand-50 dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800 text-brand-600 dark:text-brand-400')}  flex items-center justify-center`}><FontAwesomeIcon icon={faPencil} className={"h-3"} /></NavLink>
    </div>
    <div className={'border-b border-slate-200 dark:border-zinc-500 -mx-3'}></div>
    <div className={"text-sm"}><FontAwesomeIcon icon={faBuilding} className={'mr-2'} />{props.deal.customer.name}</div>
    <div className={"text-sm"}><FontAwesomeIcon icon={faUser} className={'mr-2'} />{props.deal.user?.name ?? '-'}</div>
    <div className={"text-xs opacity-80 italic flex items-center justify-between"}>
      <span>Update:</span>
      <span>{humanTimeDiff(props.deal.updatedAt)}</span>
    </div>
  </div>
}