import React, {useEffect, useMemo, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SimplePaginateProps<T> {
  data: T[]
  resultsBuilder: (results: T[]) => JSX.Element
  paginationLimit?: number
}

const PAGINATION_LIMIT_DEFAULT = 25

export function SimplePaginate<T>(props: SimplePaginateProps<T>): JSX.Element {
  const paginationLimit = props.paginationLimit ?? PAGINATION_LIMIT_DEFAULT

  const [page, setPage] = useState(0)
  const pageCount = useMemo(() => {
    return Math.ceil(props.data.length / paginationLimit)
  }, [paginationLimit, props.data])
  useEffect(() => {
    if (page >= pageCount && pageCount > 0) {
      setPage(pageCount - 1)
    } else if (page < 0) {
      setPage(0)
    }
  }, [page, pageCount]);
  const filteredAndPaginatedData = useMemo(() => {
    return props.data.slice(page * paginationLimit, (page + 1) * paginationLimit)
  }, [page, props.data, paginationLimit])
  const pages = useMemo(() => {
    const pages = []
    for (let i= 0; i < pageCount; i++) {
      pages.push(i)
    }
    return pages
  }, [pageCount, page])

  const paginationButtonBaseStyle = 'rounded h-8 w-8 flex items-center justify-center font-medium'
  return <div>
    <div>
      {props.resultsBuilder(filteredAndPaginatedData)}
    </div>
    {pageCount > 1 ? <div className={"flex"}>
      <div className={"flex items-center justify-start mt-4 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded-lg text-brand-800 dark:text-brand-600 p-1 space-x-1"}>
        <button className={`${paginationButtonBaseStyle} ${page <= 0 ? 'text-slate-300 dark:text-zinc-500' : ' hover:bg-brand-100 hover:text-brand-600 hover:dark:bg-brand-700 hover:dark:text-brand-200'}`} disabled={page <= 0} onClick={() => setPage(p => p-1)}>
          <FontAwesomeIcon icon={fa.faChevronLeft} />
        </button>
        {pages.map((i) => {
          if (pageCount > 9) {
            if (((page - i) < 3 && (i - page) < 3) || (i < 7 && page < 5) || (i > (pageCount - 8) && page > (pageCount - 5))) {
              return <button className={`${paginationButtonBaseStyle} hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-600 hover:dark:text-brand-200 ${page === i ? 'bg-brand-50 dark:bg-brand-700 border border-brand-500 dark:text-brand-200' : ''}`} key={i} onClick={() => setPage(i)}>
                {i+1}
              </button>
            } else if (i === 0 || i === (pageCount - 1)) {
              return <button className={`${paginationButtonBaseStyle} hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-600 hover:dark:text-brand-200 ${page === i ? 'bg-brand-50 dark:bg-brand-700 border border-brand-500 dark:text-brand-200' : ''}`} key={i} onClick={() => setPage(i)}>
                {i+1}
              </button>
            } else {
              if (i === 1 || i ===(pageCount - 2)) {
                return <div className={`${paginationButtonBaseStyle} text-slate-200 dark:text-zinc-500`}>...</div>;
              }
            }
          } else {
            return <button
              className={`${paginationButtonBaseStyle} hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-600 hover:dark:text-brand-200 ${page === i ? 'bg-brand-50 dark:bg-brand-700 border border-brand-500 dark:text-brand-200' : ''}`}
              key={i} onClick={() => setPage(i)}>
              {i + 1}
            </button>
          }
        })}
        <button className={`${paginationButtonBaseStyle} ${(page+1) >= pageCount ? 'text-slate-300 dark:text-zinc-500' : 'hover:bg-brand-100 hover:text-brand-600 hover:dark:bg-brand-700 hover:dark:text-brand-200'}`} disabled={(page+1) >= pageCount} onClick={() => setPage(p => p+1)}>
          <FontAwesomeIcon icon={fa.faChevronRight} />
        </button>
      </div>
    </div> : null}
  </div>
}