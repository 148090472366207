import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Ticket, TicketNote, TicketReply} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";

export const EditNoteModal: FC<{ticket: Ticket, note: TicketNote}> = (props) => {
  const [note, setNote] = useState(props.note.note)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editNote} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editNote(
      props.ticket.id,
      props.note.id, {
      note: note,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [note])

  return <>
    <form onSubmit={() => save()} className={"flex-1"}>
      <div className={"items-center"}>
        <textarea className={"min-w-full min-h-40 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-600 rounded px-2 py-1"} value={note} onChange={(event) => setNote(event.target.value)} />
        <InputErrors errors={errors} field={'note'} />
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}