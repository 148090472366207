import React, {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faBuilding} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../api/api";
import {useModal} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useTenant} from "../tenant/TenantContext";
import {useRefreshEffect} from "../components/RefreshController";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button} from "../components/form/Button";
import {Customer} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {AddRelationModal} from "../modals/AddRelationModal";
import {Skeleton} from "../components/data/Skeleton";
import {TagPill} from "../components/content/TagPill";

export const Relations: FC = () => {
  const {getCustomers} = useApiCall()
  const {open: openCreateModal} = useModal({title: 'Relatie toevoegen', body: <AddRelationModal />, size: "md"})
  const {resource: customers, reload: reloadCustomers, loading: loadingCustomers} = useFetchedResource(() => getCustomers())
  const {tenant} = useTenant()

  useRefreshEffect(() => {
    reloadCustomers(undefined)
  })

  return (
    <ContentContainer size={'xl'}>
      <PageIconHeader icon={faBuilding}>
        <Breadcrumbs crumbs={[
          {label: "CRM", href: `/${tenant}/relations`},
        ]} currentPage={"Relaties"} />
        <PageHeader>Relaties</PageHeader>
      </PageIconHeader>

      <Callout>
        <SectionHeader>Beheer je relaties</SectionHeader>
        <Paragraph>
          Relaties zijn te organiseren door middel van labels.
        </Paragraph>
        <Button size={"sm"} type={"primary"} text={"Relatie toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
      </Callout>

      {loadingCustomers ? <Skeleton type={'card'} /> :
        <CustomerList customers={customers ?? []} reload={() => reloadCustomers(undefined)} />
      }
    </ContentContainer>
  )
}

const CustomerList: FC<{customers: Customer[], reload: () => void}> = (props) => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const filteredCustomers = props.customers
    .sort((a, b) => a.name.localeCompare(b.name))
    .map<Customer&{tagString:string}>(customer => ({...customer, tagString: customer.tags.map(tag => tag.tag?.name).join(',')}))
  return <SearchPaginateReload data={filteredCustomers} placeholder={'Zoek op naam, e-mail, telefoonnummer, etc.'} filterProperties={['name', 'email', 'phone', 'contact_person', 'tagString']} onReload={() => props.reload()} paginationEnabled={true} resultsBuilder={(filteredCustomers) => {
    return <DataTable
      keyProperty={'id'}
      data={filteredCustomers}
      columns={[
        {
          header: 'Naam',
          property: 'name'
        },
        {
          header: 'Tags',
          property: 'name',
          transform: (_, customer) => {
            if (customer.tags.length === 0) {
              return <div>-</div>
            }
            return <div className={"flex flex-wrap gap-1"}>
              {customer.tags.map((tagCustomer, i) => <TagPill key={i} tag={tagCustomer.tag!} size={'sm'} />)}
            </div>;
          }
        },
        {
          header: 'E-mail',
          property: 'email'
        },
        {
          header: 'Telefoon',
          property: 'phone'
        },
        {
          header: 'Contactpersoon',
          property: 'contact_person'
        },
      ]}
      placeholder={<>Nog geen relaties</>}
      onClickRow={(customer) => navigate(`/${tenant}/relations/${customer.id}`)}
    />
  }} />
}