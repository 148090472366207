import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {TicketPriority} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";

export const EditPriorityModal: FC<{priority: TicketPriority, tenant: string}> = (props) => {
  const [priority, setPriority] = useState(props.priority.priority)
  const [order, setOrder] = useState(props.priority.order)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editPriority} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editPriority(
      props.priority.id,{
      priority: priority,
      order: order,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [priority, order])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"items-center space-x-4"}>
        <Input label={"Status"} type={"text"} value={priority} onChange={(v) => setPriority(v)}/>
        <InputErrors errors={errors} field={'priority'} />
      </div>
      <div className={"space-x-2"}>
        <Input type={"number"} label={"Order"} value={order} onChange={(n) => setOrder(n)}/>
        <InputErrors errors={errors} field={'order'} />
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}