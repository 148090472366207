import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Customer, Deal, dealStage, User} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {InputErrors} from "../components/form/InputErrors";
import {Select} from "../components/form/Select";

export const EditDealModal: FC<{customers: Customer[], users: User[], deal: Deal}> = (props) => {
  const {editDeal} = useApiCall()
  const refactoredCustomers = props.customers.map((item) => [item.id, item.name])
  const refactoredUsers = props.users.map((item) => [item.id, item.name])
  const [title, setTitle] = useState(props.deal.name)
  const [amount, setAmount] = useState(props.deal.amount)
  const [purchasePrice, setPurchasePrice] = useState(props.deal.purchase_price)
  const [stage, setStage] = useState(props.deal.stage)
  const [customer, setCustomer] = useState(props.deal.customer.id)
  const [user, setUser] = useState(props.deal.user?.id ?? '')
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editDeal(props.deal.id, {
      name: title,
      amount,
      purchasePrice,
      stage,
      customerId: customer,
      userId: user,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [customer, title, amount, purchasePrice, stage, user])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={""}>
        <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
        <InputErrors errors={errors} field={'subject'}/>
      </div>
      <div className={"flex space-x-4"}>
        <div className={"basis-1/2"}>
          <Input label={"Verkoop bedrag (€)"} type={"number"} value={amount} min={0} step={0.01}
                 onChange={(v) => setAmount(v)}/>
          <InputErrors errors={errors} field={'amount'}/>
        </div>
        <div className={"basis-1/2"}>
          <Input label={"Inkoop bedrag (€)"} type={"number"} value={purchasePrice} min={0} step={0.01}
                 onChange={(v) => setPurchasePrice(v)}/>
          <InputErrors errors={errors} field={'purchase_price'}/>
        </div>
      </div>
      <div className={""}>
        <Select label={"Status"} options={{
          'new': 'Nieuw',
          'exploring': 'Verkennen',
          'negotiating': 'Onderhandelen',
          'quoting': 'Offerte',
          'won': 'Gewonnen',
          'lost': 'Verloren',
        }} value={stage} onChange={(v) => setStage(v as dealStage)}/>
        <InputErrors errors={errors} field={'stage'}/>
      </div>
      <div className={""}>
        <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                      onChange={(v) => setCustomer(v)}/>
        <InputErrors errors={errors} field={'customer_id'}/>
      </div>
      <div className={""}>
        <Autocomplete label={"Agent"} options={Object.fromEntries(refactoredUsers)} value={user}
                      onChange={(v) => setUser(v)}/>
        <InputErrors errors={errors} field={'user_id'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}