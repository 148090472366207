import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {Customer, Sla, TicketPriority} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {InputErrors} from "../components/form/InputErrors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {SectionHeader} from "../components/content/SectionHeader";
import {SectionDivider} from "../components/content/SectionDivider";

export const DeleteCustomerSLAModal: FC<{customer: Customer, slas: Sla[]}> = (props) => {
  const {setCustomerSla} = useApiCall()
  const customerSlas = props.slas.filter((item) => item.id === props.customer.sla_id)
  const refactoredSlas = customerSlas.map((item) => [item.id, item.sla_name])
  const [sla, setSla] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  //TODO: Remove only the selected Sla_uuid when multiple sla's exists for the customer
  const save = useCallback( async () => {
    const deployment = await setCustomerSla(props.customer.id, sla).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [sla])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
        <div className={"items-center space-x-4"}>
          <Select label={"Sla Type"} options={Object.fromEntries(refactoredSlas)} value={sla}  onChange={(v) => setSla(v)}/>
          <InputErrors errors={errors} field={'sla'}/>
        </div>

    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}