import React, {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {PageHeader} from "../components/content/PageHeader";
import {faArrowRight, faBuilding,} from "@fortawesome/free-solid-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {Tenant} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

export const TenantChoice: FC = () => {
  const {getTenants} = useApiCall();
  const {resource: userTenants, loading: loadTenants, reload: reloadTenants} = useFetchedResource(() => getTenants())

  useRefreshEffect(() => {
    reloadTenants(undefined)
  })
  return (
    <ContentContainer>
      <PageIconHeader icon={faBuilding}>
        <PageHeader>Select your environment</PageHeader>
      </PageIconHeader>

      {userTenants && <>
        <TenantList tenant={userTenants}/>
      </>}
    </ContentContainer>
  )
}

const TenantList: FC<{tenant: Tenant[]}> = (props) => {
  const navigate = useNavigate()
  const {getTenantLogoURL} = useApiCall()

  return <>
    <div className={"grid grid-cols-3 gap-8"}>
      {props.tenant.map((tenant, i) => {
        return <div key={i} className={"bg-white dark:bg-zinc-700 rounded-lg border dark:border-zinc-600 overflow-hidden shadow-lg"} onClick={() => navigate(`${tenant.slug}/dashboard`)}>
          <div className={getTenantGradientColor(tenant)}></div>
          {tenant.logo ? <img src={getTenantLogoURL(tenant.slug)} alt="" className={"h-20 w-20 rounded-lg border-4 border-white dark:border-zinc-700 -mt-12 ml-4"} /> : <div className={"h-20 w-20 bg-blue-800 rounded-lg border-4 border-white -mt-12 ml-4"} />}
          <div className={`my-4 px-4`}>
            <h2 className={"text-2xl font-medium"}>{tenant.name}</h2>
            <h2 className={"text-l text-slate-500 dark:text-zinc-200"}>{tenant.role}</h2>
            <button className={"mt-4 h-12 rounded-lg w-full text-white font-medium " + getTenantButtonColor(tenant)}>Naar omgeving <FontAwesomeIcon className={"ml-2"} icon={faArrowRight} /></button>
          </div>
        </div>
      })}
    </div>
  </>
}

function getTenantGradientColor(tenant: Tenant) {
  switch (tenant.color_palette) {
    case 'red':
      return 'h-32 bg-gradient-to-b from-rose-500 to-red-600';
    case 'orange':
      return 'h-32 bg-gradient-to-b from-amber-500 to-orange-600'
    case 'amber':
      return 'h-32 bg-gradient-to-b from-yellow-500 to-amber-600'
    case 'yellow':
      return 'h-32 bg-gradient-to-b from-amber-400 to-yellow-600'
    case 'lime':
      return 'h-32 bg-gradient-to-b from-green-500 to-lime-600'
    case 'green':
      return 'h-32 bg-gradient-to-b from-emerald-500 to-green-600'
    case 'emerald':
      return 'h-32 bg-gradient-to-b from-teal-500 to-emerald-600'
    case 'teal':
      return 'h-32 bg-gradient-to-b from-cyan-500 to-teal-600'
    case 'cyan':
      return 'h-32 bg-gradient-to-b from-sky-400 to-cyan-500';
    case 'sky':
      return 'h-32 bg-gradient-to-b from-blue-400 to-sky-500';
    case 'blue':
      return 'h-32 bg-gradient-to-b from-indigo-400 to-blue-500';
    case 'indigo':
      return 'h-32 bg-gradient-to-b from-violet-400 to-indigo-500';
    case 'violet':
      return 'h-32 bg-gradient-to-b from-purple-500 to-violet-600';
    case 'purple':
      return 'h-32 bg-gradient-to-b from-fuchsia-500 to-purple-500';
    case 'fuchsia':
      return 'h-32 bg-gradient-to-b from-purple-400 to-fuchsia-500';
    case 'pink':
      return 'h-32 bg-gradient-to-b from-rose-400 to-pink-500';
    case 'rose':
      return 'h-32 bg-gradient-to-b from-red-400 to-rose-600';
  }
}

function getTenantButtonColor(tenant: Tenant) {
  switch (tenant.color_palette) {
    case 'red':
      return 'bg-red-600 dark:bg-red-700';
    case 'orange':
      return 'bg-orange-600 dark:bg-orange-700'
    case 'amber':
      return 'bg-amber-600 dark:bg-amber-600'
    case 'yellow':
      return 'bg-yellow-600 dark:bg-yellow-600'
    case 'lime':
      return 'bg-lime-600 dark:bg-lime-700'
    case 'green':
      return 'bg-green-600 dark:bg-green-700'
    case 'emerald':
      return 'bg-emerald-600 dark:bg-emerald-700'
    case 'teal':
      return 'bg-teal-600 dark:bg-teal-700'
    case 'cyan':
      return 'bg-cyan-500 dark:bg-cyan-700';
    case 'sky':
      return 'bg-sky-500 dark:bg-sky-700'
    case 'blue':
      return 'bg-blue-600 dark:bg-blue-700';
    case 'indigo':
      return 'bg-indigo-600 dark:bg-indigo-700'
    case 'violet':
      return 'bg-violet-600 dark:bg-violet-700'
    case 'purple':
      return 'bg-purple-500 dark:bg-purple-700'
    case 'fuchsia':
      return 'bg-fuchsia-600 dark:bg-fuchsia-700'
    case 'pink':
      return 'bg-pink-600 dark:bg-pink-700'
    case 'rose':
      return 'bg-rose-600 dark:bg-rose-700'
  }
}