import React, {FC} from "react";

export const Test: FC = () => {

  return (
    <div>
      <h1 className={"h-12 flex items-center text-2xl mb-6 font-bold"}>Test</h1>
      <p>hallo</p>
    </div>
  );
}
