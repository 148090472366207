import React, {FC, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {Ticket, TicketAttachment, TicketLog, TicketNote, TicketReply} from "../../../api/dto";
import {usePermission} from "../../../permissions/PermissionContext";
import {useModal} from "../../layout/ModalProvider";
import {EditDescriptionModal} from "../../../modals/EditDescriptionModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {useKeycloak} from "@react-keycloak/web";
import {
  faBan,
  faChevronDown, faChevronUp, faCircleDown,
  faClockRotateLeft, faEllipsisVertical,
  faPaperclip,
  faStickyNote,
  faTimes, faTrash,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../../../api/api";
import {useRefresh} from "../../RefreshController";
import {Button} from "../../form/Button";
import {EditReplyModal} from "../../../modals/EditReplyModal";
import {EditNoteModal} from "../../../modals/EditNoteModal";

export const TicketTimelineTab: FC<{ ticket: Ticket }> = props => {
  return <>
    <TicketDescription ticket={props.ticket}/>
    <TicketTimeline ticket={props.ticket} attachments={props.ticket.attachments} notes={props.ticket.notes} replies={props.ticket.replies} logs={props.ticket.logs}/>
    <TicketReplyForm ticket={props.ticket} />
  </>
}

const TicketDescription: FC<{ ticket: Ticket }> = ({ ticket }) => {
  const { canEditTicketDescription } = usePermission();
  const { open: openEditModal } = useModal({title: 'Beschrijving bewerken', body: <EditDescriptionModal ticket={ticket} />,});
  return (
    <div className="flex flex-col mt-4 mb-8">
      <div className="bg-white dark:bg-zinc-700 rounded border border-slate-300 dark:border-zinc-500 px-3 py-2 min-h-36 flex flex-col">
        <div className="flex justify-between">
          <RenderText text={ticket.description ? ticket.description : ''} />
          {canEditTicketDescription(ticket) && (
            <FontAwesomeIcon className="p-1 rounded border border-slate-300 dark:border-zinc-500 text-brand-700 dark:text-brand-600 hover:bg-brand-100 hover:dark:bg-zinc-600 hover:dark:border-brand-700" icon={faPenToSquare} onClick={openEditModal}/>
          )}
        </div>
      </div>
    </div>
  );
};


const TicketTimeline: FC<{ ticket: Ticket, notes: TicketNote[], attachments: TicketAttachment[], replies: TicketReply[], logs: TicketLog[] }> = (props) => {
  const keycloak = useKeycloak()
  const {canEditTicketNote, canEditTicketReply} = usePermission()
  const maxItems = 5
  const allItems = [...props.notes, ...props.attachments, ...props.replies, ...props.logs]
  const allItemsSorted = allItems.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? 1 : -1)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const items = allItemsSorted.length > 5 && isCollapsed ? [...allItemsSorted].reverse().slice(0, maxItems).reverse() : allItemsSorted


  return <>

    {allItemsSorted.length === 0 && <>
      <div className={"flex items-stretch -my-8"}>
        <div className={"flex flex-col items-start min-w-48"}>
          <div className={"w-8 h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
          <div className={"flex items-center"}>
            <button className={"h-8 mx-2 rounded flex items-center justify-center border border-brand-200 dark:border-brand-600 px-3 text-brand-900 dark:text-brand-200 text-sm"}>
              Dit ticket heeft nog geen tijdlijn items.
            </button>
          </div>
          <div className={"w-8 flex-1 min-h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
        </div>
      </div>
    </>}

    <div className={`${allItemsSorted.length === 0 ? '-my-4' : '-my-8'}`}>
      {isCollapsed && items.length !== allItemsSorted.length && <>
        <div className={"flex items-stretch"}>
          <div className={"flex flex-col items-start min-w-48"}>
            <div className={"w-8 h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
            <div className={"flex items-center"}>
              {/*Avatar*/}
              <button className={"h-8 mx-2 rounded flex items-center justify-center border border-brand-200 dark:border-brand-600 px-3 text-brand-900 dark:text-brand-200 hover:dark:text-brand-100 hover:bg-brand-100 hover:dark:bg-brand-700 text-sm"} onClick={() => setIsCollapsed(false)}>
                { allItemsSorted.length - items.length } verborgen berichten tonen
              </button>
            </div>
            <div className={"w-8 flex-1 min-h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
          </div>
        </div>
      </>}
      {items.map((item, i) => {
        if (item instanceof TicketNote) {
          return <TicketTimelineRow key={i} icon={faStickyNote} time={item.createdAt} name={item.userName} content={
            <div className={"border-l-4 border-yellow-600 rounded"}>
              <div className={"flex px-3 py-2 shadow dark:shadow-none rounded dark:border-2 dark:border-l-0 dark:border-dashed dark:border-yellow-600  text-yellow-900 dark:text-yellow-500 bg-yellow-100 dark:bg-zinc-700"}>
                <div className={'flex-1'}>
                  <RenderText text={item.note}/>
                  {item.createdAt.getTime() !== item.updatedAt.getTime() && <p className={"text-xs text-slate-400 dark:text-yellow-200"}>
                    (edited)
                  </p>}
                </div>

                {canEditTicketNote(item) && <>
                  <EditDeleteButton ticket={props.ticket} type={item} />
                </>}
              </div>
            </div>}/>
        }
        if (item instanceof TicketAttachment) {
          return <TicketTimelineRow key={i} icon={faPaperclip} time={item.createdAt} name={item.userName ?? ''}
                                    content={<div className={"flex items-center font-medium text-brand-600 hover:text-brand-700 px-1 gap-1 rounded hover:cursor-pointer"}>
                                      <RenderFile ticket={props.ticket} attachment={item}/>
                                    </div>}/>
        }
        if (item instanceof TicketReply) {
          return <TicketTimelineRow key={i} icon={faComment} time={item.createdAt} name={item.userName} content={<div
            className={"flex px-3 py-2 shadow border-l-4 border-brand-500 dark:border-brand-600 rounded text-slate-900 dark:text-zinc-200 bg-white dark:bg-zinc-700"}>
            <div className={"flex-1"}>
              <RenderText text={item.reply}/>
              {item.createdAt.getTime() !== item.updatedAt.getTime() && <p className={"text-xs text-slate-400 dark:text-zinc-200"}>
                (edited)
              </p>}
            </div>

            {canEditTicketReply(item) && <>
              <EditDeleteButton ticket={props.ticket} type={item} />
            </>}
          </div>}/>
        }
        return <TicketTimelineRow key={i} icon={faClockRotateLeft} time={item.createdAt} name={item.userName} content={
          <div className={"text-slate-500 dark:text-zinc-400 font-medium items-center space-x-2 border-dashed border-2 border-slate-200 dark:border-zinc-700 dark:bg-zinc-800 px-3 py-2 rounded"}><RenderText text={item.log}/></div>}/>
      })}
      {!isCollapsed && allItemsSorted.length > maxItems && <>
        <div className={"flex items-stretch"}>
          <div className={"flex flex-col items-start min-w-48"}>
            <div className={"w-8 h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
            <div className={"flex items-center"}>
              {/*Avatar*/}
              <button className={"h-8 mx-2 rounded flex items-center justify-center border border-brand-200 dark:border-brand-600 px-3 text-brand-900 dark:text-brand-200 hover:dark:text-brand-100 hover:bg-brand-100 hover:dark:bg-brand-700 text-sm"} onClick={() => setIsCollapsed(true)}>
                Verberg oudste { allItemsSorted.length - maxItems } berichten
              </button>
            </div>
            <div className={"w-8 flex-1 min-h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
          </div>
        </div>
      </>}
    </div>
  </>

}

const TicketTimelineRow: FC<{ name: string, time: Date, icon: IconDefinition, content: ReactNode }> = (props) => {
  return <>
    <div className={"flex items-stretch"}>
      <div className={"flex flex-col items-start min-w-48"}>
        <div className={"w-8 h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
        <div className={"flex items-center"}>
          {/*Avatar*/}
          <div
            className={"bg-brand-200 dark:bg-brand-600 text-brand-700 dark:text-brand-200 text-lg h-12 w-12 mx-2 rounded-full flex items-center justify-center"}>
            <FontAwesomeIcon icon={props.icon} />
          </div>
          {/*  Extra info*/}
          <div>
            <div className={"text-sm font-bold text-brand-900 dark:text-brand-200"}>{props.name}</div>
            <div className={"text-xs"}>{props.time.toLocaleDateString('nl', {weekday: 'short', day: 'numeric', month: 'short'})} &middot; {props.time.toLocaleTimeString('nl', {hourCycle: 'h24', hour: '2-digit', minute: '2-digit'})}</div>
          </div>
        </div>
        <div className={"w-8 flex-1 min-h-4 border-r-[4px] ml-[2px] border-brand-200 dark:border-brand-600"}></div>
      </div>
      <div className={"flex-1 py-4 pr-2 content-center"}>
        {props.content}
      </div>
    </div>
  </>
}

const TicketReplyForm: FC<{ticket: Ticket}> = (props) => {
  const [selected, setSelected] = useState<'reply'|'note'>('reply')
  const [input, setInput] = useState('')
  const [files, setFiles] = useState<File[]>([]);
  const {addReply, addNote, addAttachments} = useApiCall();
  const fileInputRef = useRef<HTMLInputElement>(null)
  const reload = useRefresh();

  const {canCreateTicketNote} = usePermission()

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.altKey && event.key === "Enter") {
        saveReply()
      } else if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
        saveNote()
      }
    };

    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [input]);

  const addFiles = (files: File[]) => {
    setFiles(old => [...old, ...files])
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      addFiles([...e.target.files])
    }
  };

  const saveReply = useCallback(async () => {
    if (files.length != 0) {
      setFiles([]);
      const deployment = await addAttachments(props.ticket.id, files)
    }

    if (input) {
      setInput('');
      const replyDeployment = await addReply(
        props.ticket.id,
        {
          reply: input,
        })
    }
    reload()
  }, [props.ticket, input, files]);

  const saveNote = useCallback(async () => {
    setInput('')
    const deployment = await addNote(
      props.ticket.id,
      {
        note: input,
      })
    reload()
  }, [props.ticket, input]);

  return <>
    {/* Reply box */}
    <div className={"my-8"}>
      <form className={"flex flex-col items-stretch bg-white dark:bg-zinc-700 rounded border border-slate-300 dark:border-zinc-500"}>
        <textarea className={"dark:bg-zinc-700 focus-within:dark:border-zinc-500 rounded-t flex-1 px-4 py-3"} rows={6} placeholder={'Typ hier...'} value={input} maxLength={64 * 1024} onChange={(event) => setInput(event.target.value)} />
        {files.length > 0 && <div className={"px-3 py-2 flex flex-wrap"}>
          {files.map((file, i) => {
            return <button type={'button'} key={i} className={'px-2 py-1 border border-slate-300 dark:border-brand-700 rounded text-xs flex items-center justify-between space-x-2 mr-2 mt-2 hover:bg-slate-50 hover:dark:bg-zinc-600 hover:text-red-800 hover:dark:text-red-500'} onClick={() => setFiles(old => old.filter((f, j) => j !== i))}>
              <span>{file.name}</span>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          })}
        </div>}
        <div className={"bg-white dark:bg-zinc-700 border-t border-slate-300 dark:border-zinc-500 rounded-b flex justify-between items-center py-1 px-2"}>
          <div>
            <Button type={"secondary"} size={"sm"} text={"Bijlage toevoegen"} icon={faPaperclip}
                    onClick={() => fileInputRef.current?.click()}/>
            <input type={'file'} multiple ref={fileInputRef} onChange={handleFileChange} className={'hidden'}
                   accept={".doc, .docx, .xls, .xlsx, .ppt, .pptx, .vsd, .vsdx, .pdf, image/*, .gif, .html, .txt, .csv"}/>
          </div>
          <div className={"flex gap-3 items-center"}>
            <div className={"flex flex-col"}>
              <p className={"text-xs text-slate-400 dark:text-zinc-200"}>Quick reply: Ctrl + alt + enter</p>
              <p className={"text-xs text-slate-400 dark:text-zinc-200"}>Quick note: Ctrl + enter</p>
            </div>
            <Button type={"primary"} size={"sm"} text={files.length === 0 ? "Reply" : "Reply and upload"} onClick={saveReply} disabled={input === '' && files.length === 0} />
            {files.length === 0 && canCreateTicketNote() && <Button type={"secondary"} size={"sm"} text={"Note"} onClick={saveNote} disabled={input === ''} />}
          </div>
        </div>
      </form>
    </div>
  </>
}

const RenderText: FC<{text: string}> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const sentences = props.text.split('\n');
  const shouldTruncate = props.text.length > 500 || sentences.length > 5;

  const getTruncatedDescription = () => {
    if (sentences.length > 5) {
      return sentences.slice(0, 5).join('\n') + '...';
    }
    if (props.text.length > 500) {
      return props.text.slice(0, 500).trim() + '...';
    }
    return props.text;
  };

  return <div className={"flex flex-col w-full"}>
    <pre className={"text-left font-sans font-normal whitespace-pre-wrap"}>{isCollapsed && shouldTruncate ? getTruncatedDescription() : props.text}</pre>
    {shouldTruncate && (
      <div className="flex justify-center">
        <FontAwesomeIcon
          className="text-brand-500 dark:text-brand-600 bg-white dark:bg-zinc-700 hover:bg-brand-200 hover:dark:bg-zinc-600 rounded-full border border-slate-200 dark:border-zinc-500 hover:border-brand-500 hover:dark:border-brand-700 p-2"
          icon={isCollapsed ? faChevronDown : faChevronUp}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
    )}
  </div>
}

const RenderFile: FC<{ticket: Ticket, attachment: TicketAttachment}> = (props) => {
  const {getAttachmentContentURL, deleteFile} = useApiCall()
  const {canDeleteAttachment} = usePermission()
  const reload = useRefresh()
  const download = async () => {
    const fileUrl = getAttachmentContentURL(props.ticket.id, props.attachment.id)
    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    link.download = props.attachment.fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const delFile = async () => {
    const deployment = await deleteFile(props.ticket.id, props.attachment.id)
    reload()
  }
  const isImage = props.attachment.fileType.startsWith('image')
  return <>
    {isImage ? <>
      <div
        className={'text-brand-900 font-medium border border-slate-300 rounded h-24 w-44 hover:opacity-75 hover:border-brand-600 relative block'}
        style={{backgroundImage: `url(${getAttachmentContentURL(props.ticket.id, props.attachment.id)})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
        onClick={() => download()}>
        <FontAwesomeIcon icon={faCircleDown} className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-brand-600'}/>
      </div>
    </> : <>
      <button
        className={'text-brand-900 dark:text-brand-600 font-medium flex items-center space-x-2 border border-slate-300 dark:border-zinc-500 hover:dark:border-brand-600 dark:bg-zinc-700 px-2 py-1 rounded hover:bg-brand-100 hover:dark:bg-brand-700 hover:dark:text-brand-200'}
        onClick={() => download()}>
        <FontAwesomeIcon icon={faCircleDown}/>
        <span className={"underline"}>{props.attachment.fileName}</span>
      </button>
    </>}
    {canDeleteAttachment(props.attachment) &&
      <FontAwesomeIcon className={"text-red-500 dark:text-red-600 hover:bg-red-100 hover:dark:bg-zinc-500 p-2 rounded"} icon={faTrash} onClick={delFile}/>
    }
  </>
}


const EditDeleteButton: FC<{ ticket: Ticket, type: TicketReply | TicketNote }> = (props) => {
  // @ts-ignore
  const {open: openEditReplyModal} = useModal({title: 'Reply bewerken', body: <EditReplyModal ticket={props.ticket} reply={props.type} />})
  // @ts-ignore
  const {open: openEditNoteModal} = useModal({title: 'Notitie bewerken', body: <EditNoteModal ticket={props.ticket} note={props.type} />})
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const {deleteReply, deleteNote} = useApiCall();
  const reload = useRefresh()

  const delNote = async () => {
    const deployment = await deleteNote(props.ticket.id, props.type.id)
    reload()
  }
  const delReply = async () => {
    const deployment = await deleteReply(props.ticket.id, props.type.id)
    reload()
  }

  return <div
    className={`relative z-10 font-medium text-brand-600 hover:text-brand-700 hover:dark:text-brand-600 px-1 gap-1 rounded hover:cursor-pointer`}>
    <button onClick={() => setIsCollapsed(x => !x)}>
      <FontAwesomeIcon icon={faEllipsisVertical} className={"text-xl hover:bg-brand-200 hover:dark:bg-brand-300 rounded py-1 px-3"}/>
    </button>

    {/* Dropdown */}
    {!isCollapsed && <div className={"flex flex-col gap-2 bg-white dark:bg-zinc-600 text-slate-700 rounded shadow-lg absolute min-w-full left-10 -top-0 border border-slate-300 dark:border-zinc-500 -mx-1 justify-center"}>
      <FontAwesomeIcon icon={faPenToSquare} className={"text-brand-600 text-xl hover:bg-brand-200 hover:dark:bg-zinc-500 p-2 rounded"} onClick={props.type instanceof TicketReply ? openEditReplyModal : openEditNoteModal}/>
      <FontAwesomeIcon icon={faBan} className={"text-red-500 text-xl hover:bg-brand-200 hover:dark:bg-zinc-500 p-2 rounded"} onClick={props.type instanceof TicketReply ? delReply : delNote}/>
    </div>
    }
  </div>
}