import React, {FC, useCallback, useState} from "react";
import {PageIconHeader} from "../../components/layout/PageIconHeader";
import {faExclamationCircle, faShop, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../../components/content/Breadcrumbs";
import {PageHeader} from "../../components/content/PageHeader";
import {ContentContainer} from "../../components/content/ContentContainer";
import {ErrorBag, useApiCall, ValidationError} from "../../api/api";
import {useFetchedResource} from "../../api/APIContext";
import {useRefresh, useRefreshEffect} from "../../components/RefreshController";
import {useTenant} from "../../tenant/TenantContext";
import {Integration} from "../../api/dto";
import {Input} from "../../components/form/Input";
import {Button} from "../../components/form/Button";
import {TabContainer} from "../../components/layout/TabContainer";
import {SectionHeader} from "../../components/content/SectionHeader";
import {Paragraph} from "../../components/Paragraph";
import {Callout} from "../../components/content/Callout";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {Card} from "../../components/Card";
import {Skeleton} from "../../components/data/Skeleton";
import {InputErrors} from "../../components/form/InputErrors";

export const WeFact: FC = () => {
  const { getIntegrations } = useApiCall();
  const {tenant} = useTenant()
  const { resource: integrationResource, loading: loadIntegrations, reload: reloadIntegrations } = useFetchedResource(getIntegrations);
  useRefreshEffect(() => {
    reloadIntegrations(undefined);
  });
  if (loadIntegrations) {
    return <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"WeFact"} />
        <PageHeader>WeFact</PageHeader>
      </PageIconHeader>
      <Skeleton type={'card'}/>
    </ContentContainer>
  }  if (!integrationResource) {
    return <Callout color={'danger'} icon={faExclamationCircle}>Er is iets verkeerd gegaan.</Callout>;
  }

  const integration = integrationResource.find(item => item.integration === 'wefact');
  return (
    <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"WeFact"} />
        <PageHeader>{integration ? integration.name : ''}</PageHeader>
      </PageIconHeader>

      {integration && <IntegrationContent integration={integration} />}
    </ContentContainer>
  )
}

const IntegrationContent: FC<{integration: Integration}> = (props) => {
  return <TabContainer tabs={{
    'Details & Informatie': <IntegrationDetails integration={props.integration} />,
    'Instellingen': <IntegrationConfig integration={props.integration} />,
  }} />
}

const IntegrationDetails: FC<{ integration: Integration }> = () => {
  return <>
    <div className={"mb-8"}>
      <SectionHeader>Beschrijving</SectionHeader>
      <Paragraph>WeFact is een Nederlands softwarepakket dat zich richt op facturatie en administratie voor bedrijven. Het is ontwikkeld om ondernemers te helpen bij het efficiënt beheren van hun financiële processen. Hier is een beschrijving van de belangrijkste kenmerken en functionaliteiten van WeFact:</Paragraph>
      <ul className={"list-disc pl-6 text-sm text-gray-700 dark:text-zinc-200 mb-4"}>
        <li>Facturatie</li>
        <li>Offertes</li>
        <li>Klantenbeheer</li>
        <li>Productbeheer</li>
        <li>Online betalingen</li>
        <li>Boekhoudingen</li>
        <li>Rapportages en analyses</li>
      </ul>
    </div>
  </>
}

const IntegrationConfig: FC<{ integration: Integration }> = (props) => {
  const {postIntegration} = useApiCall()
  const refresh = useRefresh()
  const [options, setOptions] = useState(props.integration.required_options);
  const [errors, setErrors] = useState<ErrorBag>({});

  const handleInputChange = (index: number, newValue: string) => {
    const newOptions = [...options];
    newOptions[index].value = newValue;
    setOptions(newOptions);
  };

  const save = useCallback(async () => {
    await postIntegration(props.integration.integration, options).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    refresh()
  }, [options]);

  const getType = (type: string): 'text'|'password' => {
    switch (type) {
      case 'string':
        return 'text';
      case 'encrypted_string':
        return 'password';
      default:
        return 'text';
    }
  };

  return (
    <>
      {!props.integration.configured && <>
        <Callout color={'warning'} icon={faWarning}>
          Deze integratie is nog niet ingesteld. Stel hem hieronder in
        </Callout>
    </>}

    {props.integration.configured && <>
      <Callout color={'success'} icon={faCircleCheck}>
        Verbinding tussen Windesk en WeFact is actief
      </Callout>
    </>}

      <Card title={props.integration.configured ? "Integratie opnieuw instellen" : 'Integratie setup'}>
        <form className={'flex flex-col'}>
          <InputErrors errors={errors} field={'message'} />
          {options.map((option, i) => (
            <div className={"mt-2 mb-4 flex items-center"} key={i}>
              <Input type={getType(option.type)} label={option.key} value={option.value ?? ''} onChange={(e: string) => handleInputChange(i, e)}/>
              <InputErrors errors={errors} field={option.key} />
            </div>
          ))}
        </form>
        <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={save} />
      </Card>
    </>
  );
};