import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Toggle} from "../components/form/Toggle";
import {TicketStatus} from "../api/dto";
import {useParams} from "react-router-dom";
import {Button} from "../components/form/Button";
import {faTriangleExclamation, faX} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const EditStatusModal: FC<{status: TicketStatus, tenant: string}> = (props) => {
  const [status, setStatus] = useState(props.status.status)
  const [isClosed, setIsClosed] = useState<boolean>(props.status.isClosed)
  const [isOnHold, setIsOnHold] = useState<boolean>(props.status.isOnHold)
  const [isDefault, setIsDefault] = useState<boolean>(props.status.isDefault)
  const [errors, setErrors] = useState<ErrorBag>({})
  console.log(isDefault)

  const {editStatus} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editStatus(
      props.status.id,{
      status: status,
      is_closed: isClosed,
      is_on_hold: isOnHold,
      is_default: isDefault,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [status, isClosed, isOnHold, isDefault])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <Input label={"Status"} type={"text"} value={status} onChange={(v) => setStatus(v)}/>
      <InputErrors errors={errors} field={'status'} />
      <div className={"flex space-x-2"}>
        <Toggle value={isClosed} onChange={setIsClosed} label={'Closed'}/>
        <Toggle value={isOnHold} onChange={setIsOnHold} label={'On-Hold'}/>
        <Toggle value={isDefault} onChange={setIsDefault} label={'Default'}/>
      </div>
      {isDefault && <p className={"text-red-600 text-sm"}>
        If a status with is_default set to true already exists it will set that value to false upon submitting.
        <FontAwesomeIcon icon={faTriangleExclamation}/>
      </p>}
      <InputErrors errors={errors} field={'is_closed'} />
      <InputErrors errors={errors} field={'is_on_hold'} />
      <InputErrors errors={errors} field={'is_default'} />
    </form>
      <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}