import React, {FC, ReactNode} from "react";

const availableSizes = {
  "sm": "max-w-screen-sm",
  "md": "max-w-screen-md",
  "lg": "max-w-screen-lg",
  "xl": "max-w-screen-xl",
  "2xl": "max-w-screen-2xl",
  "full": "w-full",
}

interface ContentContainerProps {
  children: ReactNode
  size?: keyof typeof availableSizes // defaults to LG
}

export const ContentContainer: FC<ContentContainerProps> = props => {
  const maxWidth = availableSizes[props.size ?? "lg"]
  return <div className={`${maxWidth} mx-auto`}>
    {props.children}
  </div>
}