import React, {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faFileContract, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button} from "../components/form/Button";
import {Callout} from "../components/content/Callout";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {Sla, SlaRuleType, TicketPriority} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteSlaModal} from "../modals/DeleteSlaModal";
import {Accordion} from "../components/data/Accordion";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";

export const SLA: FC = () => {
  const {tenant} = useTenant()
  const {getAllSlas, getAllPriorities} = useApiCall()
  const {resource: SlaItems, reload: reloadSla, loading: loadSla} = useFetchedResource(() => getAllSlas())
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const navigate = useNavigate()

  useRefreshEffect(() => {
    reloadSla(undefined)
    reloadPriorities(undefined)
  })

  return (
    <ContentContainer>
      <PageIconHeader icon={faFileContract}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
        ]} currentPage={"SLA"} />
        <PageHeader>SLA</PageHeader>
      </PageIconHeader>

      <Callout>
        <SectionHeader>Service Level Agreements</SectionHeader>
        <Paragraph>
          Beheer hier de Service Level Agreements (SLA's) die beschikbaar zijn voor je klanten binnen deze omgeving.
        </Paragraph>
        <Button size={"sm"} type={"primary"} text={"SLA toevoegen"} icon={faAdd} onClick={() => navigate(`/${tenant}/sla/create`)} />
      </Callout>

      {SlaItems && ticketPriorities && tenant &&
        // <SlaTable sla={SlaItems} priorities={ticketPriorities}/>
        <SlaAccordion sla={SlaItems} priorities={ticketPriorities} tenant={tenant}/>
      }

    </ContentContainer>
  )
}

const SlaAccordion: FC<{sla: Sla[], priorities: TicketPriority[], tenant: string}> = (props) => {

  function slaNameRefactor(header: string): string {
    switch (header) {
      case SlaRuleType.maxResponse:
        return 'Maximale reactie tijd (in minuten)';
      case SlaRuleType.maxSolve:
        return 'Maximale oplos tijd (in minuten)';
      case SlaRuleType.uptime:
        return 'Uptime (in %)';
      case SlaRuleType.supportHours:
        return 'Aantal support uren';
      default:
        return 'Unknown SLA Rule';
    }
  }

  return <div>
    {props.sla.map((slaItem, i) => {
      const rulesMap: { [ruleType: string]: { [priorityId: string]: { [timeWindow: string]: string } } } = {}
      slaItem.rules.forEach(rule => {
        if (!rulesMap[rule.rule_type]) {
          rulesMap[rule.rule_type] = {}
        }
        if (!rulesMap[rule.rule_type][rule.priority_id]) {
          rulesMap[rule.rule_type][rule.priority_id] = {}
        }
        const hoursKey = rule.during_office_hours ? 'inside_office_hours' : 'outside_office_hours'
        rulesMap[rule.rule_type][rule.priority_id][hoursKey] = rule.value
      })
      const headerStyle = 'font-medium text-sm text-left pr-8 py-2 whitespace-nowrap'
      const dataStyle = 'text-sm text-left pr-8 py-2 whitespace-nowrap'
      const sortedPriorityIds = props.priorities.map(priority => priority.id);
      return <Accordion key={i} header={slaItem.sla_name}
                        data={Object.entries(rulesMap).map(([ruleType, priorityMap]) => {
                          return {
                            itemHeader: slaNameRefactor(ruleType),
                            children: <table>
                              <thead>
                              <tr>
                                <th className={headerStyle}>Prioriteit</th>
                                <th className={headerStyle}>Tijdens kantooruren</th>
                                <th className={headerStyle}>Buiten kantooruren</th>
                              </tr>
                              </thead>
                              <tbody>
                              {sortedPriorityIds.map(priorityId => {
                                const { inside_office_hours, outside_office_hours } = priorityMap[priorityId];
                                const priorityName = props.priorities.find(p => p.id === priorityId)?.priority;

                                return (
                                  <tr key={priorityId} className={""}>
                                    <th className={headerStyle}>{priorityName}</th>
                                    <td className={dataStyle}>{inside_office_hours ? inside_office_hours : 'Nog niet ingesteld'}</td>
                                    <td className={dataStyle}>{outside_office_hours ? outside_office_hours : 'Nog niet ingesteld'}</td>
                                    <td className={"w-full"}>
                                      <div className={"flex justify-end"}>
                                        {/* Additional content if needed */}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              </tbody>
                            </table>
                          }
                        })} editButton={<EditSlaButton tenant={props.tenant} sla={slaItem}/>}
                            deleteButton={<DeleteSlaButton sla={slaItem} />}
      />
    })}
  </div>
}

const EditSlaButton: FC<{tenant: string, sla: Sla}> = props => {
  const navigate = useNavigate()
  return <FontAwesomeIcon className={"p-1 text-brand-500 dark:text-brand-500 hover:bg-brand-200 hover:dark:bg-zinc-500 border border-transparent hover:border-slate-200 hover:dark:border-brand-600 rounded"} icon={faPen} size={"xs"} onClick={() => navigate(`/${props.tenant}/sla/${props.sla.id}/edit`)} />
}
const DeleteSlaButton: FC<{sla: Sla}> = props => {
  const {open: openDeleteSlaModal} = useModal({title: 'SLA verwijderen', body: <DeleteSlaModal sla={props.sla} />})
  return <FontAwesomeIcon className={"p-1 text-red-500 dark:text-red-600 hover:bg-red-200 hover:dark:bg-zinc-500 border border-transparent hover:border-slate-200 hover:dark:border-red-600 rounded"} icon={faTrash} size={"xs"} onClick={openDeleteSlaModal} />
}
