import React, {FC, ReactNode} from "react";

interface HeaderProps {
  children: ReactNode
}

export const SectionHeader: FC<HeaderProps> = props => {
  return <h2 className={"text-lg font-bold mb-4 text-slate-600 dark:text-zinc-300"}>
    {props.children}
  </h2>
}