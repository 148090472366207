import {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";

export const Reports: FC = () => {

  return (
    <ContentContainer>
      <PageIconHeader icon={faChartPie}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Rapportages"} />
        <PageHeader>Rapportages</PageHeader>
      </PageIconHeader>
    </ContentContainer>
  )
}