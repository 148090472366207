import React, {FC, useCallback, useState} from "react";
import {Customer, Ticket} from "../../../api/dto";
import {useApiCall} from "../../../api/api";
import {useRefresh} from "../../RefreshController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import {Autocomplete} from "../../form/Autocomplete";
import {Button} from "../../form/Button";

export const HelpAssignCustomer: FC<{ ticket: Ticket, customers: Customer[] }> = (props) => {
  const refactoredCustomers = props.customers.map((item) => [item.id, item.name])
  const [customer, setCustomer] = useState('')
  const {editTicket} = useApiCall()
  const reload = useRefresh()

  const saveNewValue = useCallback(async (customer: string) => {
    const deployment = await editTicket(
      props.ticket.id,
      {
        customer_uuid: customer,
      })
    reload()
  }, []);

  return <div className={"flex "}>
    <div className={"flex-1 my-4 rounded border border-amber-400 dark:border-amber-700 bg-amber-50 dark:bg-zinc-700 min-h-14"}>
      <div className={"flex space-x-4 items-center justify-between px-4 py-3"}>
        <div className={"flex-1 text-amber-900 dark:text-amber-500"}>
          <div className={'flex items-center font-bold'}>
            <FontAwesomeIcon icon={faWarning} bounce={true} className={"mr-3 h-4 w-4"} />
            <p>Klant is nog niet gekoppeld</p>
          </div>
          <div className={'ml-7 text-sm font-medium mt-1'}>
            <p>Dit ticket kon niet automatisch aan een klant gekoppeld worden. Koppel het handmatig. Vul de contactpersonen onder de desbetreffende klant aan om dit in de toekomst te voorkomen.</p>
          </div>
        </div>
        <div className={"-mt-1"}>
          <Autocomplete label={''} options={Object.fromEntries(refactoredCustomers)} value={customer}
                        onChange={(v) => setCustomer(v)}/>
        </div>
        <div>
          <Button type={"primary"} size={"md"} text={"Koppel"} disabled={!customer} onClick={() => saveNewValue(customer)}/>
        </div>
      </div>
    </div>
  </div>
}