import React, {FC, FormEvent, useEffect, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd, faArchive, faCheck,
  faEnvelope, faEuro,
  faFileContract, faHandHoldingDollar, faInbox,
  faLocationPin, faMagnifyingGlass, faPencil, faPersonDigging,
  faPhone, faSackDollar, faSave, faSquareArrowUpRight, faTag, faTimes,
  faTrash,
  faUser, IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime, useRefresh, useRefreshEffect} from "../components/RefreshController";
import {Customer, Deal, dealStage, Sla, SlaRuleType, Tag, TicketPriority, User} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import {Button, IconButton} from "../components/form/Button";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {useModal} from "../components/layout/ModalProvider";
import {Accordion} from "../components/data/Accordion";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Card, CardDivider} from "../components/Card";
import {Skeleton} from "../components/data/Skeleton";
import {EditRelationModal} from "../modals/EditRelationModal";
import {CustomerAvatar} from "../components/content/CustomerAvatar";
import {EditTagModal} from "../modals/EditTagModal";
import {DeleteTagModal} from "../modals/DeleteTagModal";
import {TagPill} from "../components/content/TagPill";
import {DefaultColors} from "tailwindcss/types/generated/colors";
import {Input} from "../components/form/Input";
import {text} from "@fortawesome/fontawesome-svg-core";
import {IconAvatar} from "../components/content/IconAvatar";
import {EditDealModal} from "../modals/EditDealModal";

const dealStageText: {[stage: string]: string} = {
  'new': 'Nieuw',
  'exploring': 'Verkennen',
  'negotiating': 'Onderhandelen',
  'quoting': 'Offerte',
  'won': 'Gewonnen',
  'lost': 'Verloren',
}

export const DealDetails: FC = () => {
  const {getDeal} = useApiCall()
  const {tenant} = useTenant()
  const {deal: dealId} = useParams()
  const {resource: deal, reload: reloadDeal, loading: loadingDeal} = useFetchedResource((dealId: string) => getDeal(dealId))
  const latestRefreshTime = useLatestRefreshTime()

  useEffect(() => {
    if (dealId === undefined) {
      console.warn('dealId is undefined')
      return
    }
    reloadDeal(dealId)
  }, [dealId, latestRefreshTime])

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faUser}>
        <Breadcrumbs crumbs={[
          {label: "CRM", href: `/${tenant}/relations`},
          {label: "Deals", href: `/${tenant}/deals`},
        ]} currentPage={deal?.name ? `Deal: ${deal.name}` : "Details"}/>
        <PageHeader>{deal?.name ? `Deal: ${deal.name}` : "Details"}</PageHeader>
      </PageIconHeader>
      <div className={"flex space-x-8"}>
        {deal ? <div className={"flex-1"}><Card title={'Deal'}>
          <DealInfo deal={deal} tenant={tenant ?? '?'} />
        </Card></div> : <div className={"flex-1"}><Skeleton type={'card'}/></div>}
        {deal ? <div className={"flex-1"}><Card title={'Notities'}>
          <DealNotes deal={deal} />
        </Card></div> : <div className={"flex-1"}><Skeleton type={'card'}/></div>}
      </div>
    </ContentContainer>
  )
}

const DealInfo: FC<{ deal: Deal, tenant: string }> = (props) => {
  const {getCustomers, getAllUsersForTenant} = useApiCall()
  const {resource: tenantUsers, loading: loadTenantUsers, reload: reloadTenantUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: customerResource, loading: loadingCustomers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  useRefreshEffect(() => {
    reloadTenantUsers(undefined)
    reloadCustomers(undefined)
  })

  const navigate = useNavigate()
  const editRelationModal = useModal({title: 'Deal bewerken', body: <EditDealModal deal={props.deal} users={tenantUsers ?? []} customers={customerResource ?? []}/>})

  return <div >
    <div className={"flex space-x-4 items-center"}>
      <CustomerAvatar customer={props.deal.customer} />
      <div className={'flex-1'}>
        <span className={"text-sm"}>{props.deal.customer.name}</span>
        <h1 className={"text-lg font-medium -mt-1"}>{props.deal.customer.contact_person}</h1>
      </div>
      <Button type={'secondary'} size={'md'} text={'Relatie bekijken'} icon={faUser} onClick={() => navigate(`/${props.tenant}/relations/${props.deal.customer.id}`)} />
    </div>
    <CardDivider />
    <div className={"space-y-4"}>
      <div className={"flex space-x-3 items-center"}>
        <IconAvatar icon={faTag}/>
        <dl className={"flex-1"}>
          <dt className={"text-sm font-medium"}>Naam</dt>
          <dd
            className={"text-brand-900 dark:text-brand-200"}>{props.deal.name}</dd>
        </dl>
      </div>
      <div className={"flex space-x-3 items-center"}>
        <IconAvatar icon={faFileContract}/>
        <dl className={"flex-1"}>
          <dt className={"text-sm font-medium"}>Status</dt>
          <dd
            className={"text-brand-900 dark:text-brand-200"}>{dealStageText[props.deal.stage] ?? props.deal.stage}</dd>
        </dl>
        <DealTransitionButton deal={props.deal}/>
      </div>
      <div className={"flex space-x-3 items-center"}>
        <IconAvatar icon={faHandHoldingDollar}/>
        <dl className={"flex-1"}>
          <dt className={"text-sm font-medium"}>Waarde</dt>
          <dd className={"flex space-x-2"}>
            <div
              className={"text-brand-900 dark:text-brand-200"}>€ {props.deal.amount.toLocaleString('nl', {
              currency: 'EUR',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}</div>
            {props.deal.purchase_price ?
            <div
              className={"text-brand-900 dark:text-brand-200"}>(winst:
              € {(props.deal.amount - props.deal.purchase_price).toLocaleString('nl', {
                currency: 'EUR',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}. {Math.floor(((props.deal.amount - props.deal.purchase_price) / props.deal.amount) * 100)}%)
            </div> : ''}
          </dd>

        </dl>
      </div>
      <div className={"flex space-x-3 items-center"}>
        <IconAvatar icon={faUser}/>
        <dl className={"flex-1"}>
          <dt className={"text-sm font-medium"}>Agent</dt>
          <dd
            className={"text-brand-900 dark:text-brand-200"}>{props.deal.user?.name ?? 'Geen'}</dd>
        </dl>
      </div>
    </div>
    <CardDivider/>
    <div className={'flex space-x-3'}>
      <Button type={'primary'} size={'md'} text={'Bewerken'} disabled={loadTenantUsers || loadingCustomers} icon={faPencil} onClick={() => editRelationModal.open()} />
      {/*<Button type={'danger'} size={'md'} text={'Archiveren'} icon={faArchive} onClick={archive} />*/}
    </div>
  </div>
}

const DealNotes: FC<{ deal: Deal }> = props => {
  const [text, setText] = useState<string>(props.deal.description ?? '')
  const dirty = text !== (props.deal.description ?? '')
  const {editDeal} = useApiCall()
  const refresh = useRefresh()
  const save = async () => {
    await editDeal(props.deal.id, {description: text})
    refresh()
  }
  return <div>
    <textarea value={text} onChange={e => setText(e.target.value)} className={"dark:bg-zinc-600 border-2 border-slate-200 dark:border-zinc-600 outline-slate-700 dark:outline-zinc-600 text-black dark:text-zinc-300 rounded text-base font-normal px-2 py-2 mt-1 h-96 w-full"} />
    <CardDivider />
    <div className={'flex space-x-3'}>
      <Button type={'primary'} disabled={!dirty} size={'md'} text={'Opslaan'} icon={faSave} onClick={save} />
    </div>
  </div>
}
const DealTransitionButton: FC<{ deal: Deal }> = props => {
  const {editDeal} = useApiCall()
  const refresh = useRefresh()
  const transition = async (stage: dealStage) => {
    await editDeal(props.deal.id, {stage})
    refresh()
  }
  return <div className={"flex space-x-2"}>
    {props.deal.stage === 'new' && <Button type={'primary'} size={'sm'} text={'Naar Verkennen'} icon={faMagnifyingGlass} onClick={() => transition('exploring')} />}
    {props.deal.stage === 'exploring' && <Button type={'primary'} size={'sm'} text={'Naar Onderhandelen'} icon={faHandHoldingDollar} onClick={() => transition('negotiating')} />}
    {props.deal.stage === 'negotiating' && <Button type={'primary'} size={'sm'} text={'Naar Offerte'} icon={faFileContract} onClick={() => transition('quoting')} />}
    {props.deal.stage === 'quoting' && <Button type={'primary'} size={'sm'} text={'Naar Gewonnen'} icon={faCheck} onClick={() => transition('won')} />}
    {props.deal.stage === 'quoting' && <Button type={'danger'} size={'sm'} text={'Naar Verloren'} icon={faTimes} onClick={() => transition('lost')} />}
  </div>
}