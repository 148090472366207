import {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faMinus} from "@fortawesome/free-solid-svg-icons";

export const Accordion: FC<{header: String, data: {itemHeader: string, children: JSX.Element}[], editButton?: JSX.Element, deleteButton?: JSX.Element, addRuleButton?: JSX.Element}> = (props) => {
  const [isOpen, setIsOpen] = useState<number|null>(null)
  return <div className={"border border-slate-200 dark:border-zinc-500 dark:bg-zinc-600 rounded my-4 px-6 py-4"}>
    <div className={"flex justify-between"}>
    <h1 className={"font-bold text-2xl"}>{props.header}</h1>
      <div className={"flex flex-row space-x-4 items-center"}>
        {props.editButton}
        {props.deleteButton}
      </div>
    </div>
    <hr className={"mb-4 dark:border-zinc-500"} />

    {props.data.map((item, i) => {
      return <AccordionItem key={i} onClick={() => setIsOpen(isOpen === i ? null : i)} isOpen={isOpen === i} title={item.itemHeader} children={item.children}/>
    })}
    {props.addRuleButton}
  </div>
}

const AccordionItem: FC<{title: string, children?: JSX.Element, isOpen: boolean, onClick: () => void}> = (props) => {
  return <>
    <div className={"flex justify-between items-center hover:bg-brand-200 hover:dark:bg-brand-700 rounded hover:cursor-pointer p-1 mt-2 "} onClick={props.onClick}>
      <h1>{props.title}</h1>
      <FontAwesomeIcon className={"pr-2"} icon={props.isOpen ? faMinus : faAdd}/>
    </div>

    {props.isOpen &&
      <div className={"px-4 py-2"}>
      <hr className={"mb-2 dark:border-zinc-500"}/>
        {props.children}
      </div>
    }
    </>
}