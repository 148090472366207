import React, {FC} from "react";
import {Cell, Label, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";

export const Piechart: FC<{data: {name: string, count: number}[], cx: number, cy: number, innerRadius: number, outerRadius: number, labelText?: string, labelValue: string}> = (props) => {
  const data = props.data;
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#ff0000", "#ff3399", "#660066"];

  return <>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            innerRadius={props.innerRadius}
            outerRadius={props.outerRadius}
          >
            {props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
            <Label
              content = {<CustomSvgLabel viewBox={{cx: props.cx, cy: props.cy}} labelText={props.labelText ?? ''} value={props.labelValue} />}
              position="center"
            />
          </Pie>
          <Legend content = {<CustomizedLegend />} />
        </PieChart>
      </ResponsiveContainer>
  </>
}

const CustomSvgLabel: FC<{viewBox: {cx: number, cy: number}, labelText: string, value: string}> = (props) => {
  let color = 'white'
  const darkTheme = window.matchMedia("(prefers-color-scheme: dark)")
  if (darkTheme.matches) {
    color = 'white'
  } else {
    color = 'black'
  }

  return <g>
    <text
      x={props.viewBox.cx}
      y={props.viewBox.cy - 10}
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
      alignmentBaseline="middle"
      fontSize="15"
      fill={color}
    >
      {props.labelText}
    </text>
    <text
      x={props.viewBox.cx}
      y={props.viewBox.cy + 15}
      className="recharts-text recharts-label text-slate-200 dark:text-zinc-900"
      textAnchor="middle"
      dominantBaseline="central"
      alignmentBaseline="middle"
      fontSize="26"
      fontWeight="600"
      fill={color}
    >
      {props.value}
    </text>
  </g>

}

const Bullet: FC<{backgroundColor: string, size: string}> = ({ backgroundColor, size }) => {
  return (
    <div
      className="rounded-full"
      style={{
        backgroundColor,
        width: size,
        height: size
      }}
    ></div>
  );
};

const CustomizedLegend: FC<{payload?: {value: string, payload: {value: string, fill: string}}[]}> = (props) => {
  return <ul className={'grid grid-cols-2 mx-3 mb-3'}>
    {
      props.payload?.map((entry, index) => (
        <li className={"flex flex-row items-center space-x-2"} key={index}>
          <Bullet backgroundColor={entry.payload.fill} size={'10px'} />
          <div className="capitalize text-sm text-slate-900 dark:text-zinc-300">{entry.value}</div>
          <div className={"font-bold text-sm dark:text-zinc-300"}>{entry.payload.value}</div>
        </li>
      ))
    }
  </ul>
};