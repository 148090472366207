import React, {FC, useCallback, useState} from "react";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Customer, Team} from "../api/dto";

export const AddTeamUserModal: FC<{team: Team}> = (props) => {
  const {addTeamUser} = useApiCall()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  // const [permissions, setPermissions] = useState('') //TODO: uncomment when permission are implemented
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh();

  const save = useCallback(async () => {
    const deployment = await addTeamUser(
      props.team.id,{
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      // permissions: permissions, //TODO: uncomment when permission are implemented
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [firstName, lastName, email, phone])
  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
        <div className={"flex items-center space-x-4"}>
          <div className={"basis-1/2"}>
            <Input label={"Voornaam"} type={"text"} value={firstName} placeholder={"Voornaam"} onChange={(v) => setFirstName(v)}/>
            <InputErrors errors={errors} field={'firstName'}/>
          </div>

          <div className={"basis-1/2"}>
            <Input label={"Achternaam"} type={"text"} value={lastName} placeholder={"Achternaam"} onChange={(v) => setLastName(v)}/>
            <InputErrors errors={errors} field={'lastName'}/>
          </div>
        </div>


      <div className={"items-center space-x-4"}>
        <Input label={"email"} type={"text"} value={email} placeholder={"E-mail"} onChange={(v) => setEmail(v)}/>
        <InputErrors errors={errors} field={'email'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Input label={"Telefoon"} type={"text"} value={phone} placeholder={"Telefoon nummer"}
               onChange={(v) => setPhone(v)}/>
        <InputErrors errors={errors} field={'phone'}/>
      </div>
      {/*//TODO: Add permissions field when permissions are implemented*/}
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}