import React, {FC, useEffect} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {AverageTime, Customer, Deal, Team, Ticket, TicketPriority, TicketStatus, User} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext"
import {Piechart} from "../components/data/Piechart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {usePersistentState} from "../util/usePersistentState";
import {Select} from "../components/form/Select";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {TagPill} from "../components/content/TagPill";
import {DealDashboard, Deals} from "./Deals";
import {Skeleton} from "../components/data/Skeleton";


export const Dashboard: FC = () => {
  const {tenant} = useTenant()
  const {getAllTicketsForTenant, getAllUsersForTenant, getCustomers, getAllPriorities, getAllStatuses, getAverageHandlingTime, getTeams, getTenant, getUserMe, getDeals} = useApiCall()
  const {resource: unfilteredTickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketStatuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: averageTime, loading: loadAvergeTime, reload: reloadAverageTime} = useFetchedResource(() => getAverageHandlingTime())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {resource: currentTenant, reload: reloadTenant} = useFetchedResource(() => getTenant())
  const {resource: me, reload: reloadMe} = useFetchedResource(() => getUserMe())
  const {resource: deals, reload: reloadDeals} = useFetchedResource(() => getDeals())
  const tickets = unfilteredTickets?.filter(ticket => !ticket.status?.isClosed) ?? []

  useEffect(() => {
    reloadTickets(undefined)
    reloadPriorities(undefined)
    reloadStatuses(undefined)
    reloadAverageTime(undefined)
    reloadCustomers(undefined)
    reloadUsers(undefined)
    reloadTeams(undefined)
    reloadTenant(undefined)
    reloadMe(undefined)
    reloadDeals(undefined)
  }, [tenant]);

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faChartLine}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Dashboard"} />
        <PageHeader>Dashboard</PageHeader>
      </PageIconHeader>

      {me && <Welcome me={me}/>}

      {currentTenant?.modules.includes('service_desk') ? <>
            {tickets && ticketStatuses && ticketPriorities && averageTime && users && teams && customers && <>
              <ServiceDeskDashboard tickets={tickets} statuses={ticketStatuses} priorities={ticketPriorities} averageTime={averageTime} users={users} teams={teams} reload={() => reloadTickets(undefined, true)} customers={customers} />
            </> }
            </> : ''}


      {currentTenant?.modules.includes('crm') ? <>
        {deals && me && <><CrmDashboard customers={customers ?? []} reload={() => reloadCustomers(undefined, true)} deals={deals} me={me}/>
        </> }
      </> : '' }

    </ContentContainer>
  )
}

const Welcome: FC<{me: User}> = (props) => {
  return <>
    <h1 className={"text-4xl font-bold mb-14"}>Welkom, {props.me.name}</h1>
  </>
}

const ServiceDeskDashboard: FC<{tickets: Ticket[], reload: () => void, statuses: TicketStatus[], priorities: TicketPriority[], averageTime: AverageTime, customers: Customer[], users: User[], teams: Team[]}> = (props) => {
  return <div className={"mb-12"}>
    <h1 className={"text-2xl mt-2 mb-3"}>Service Desk</h1>
    {props.tickets && props.statuses && props.priorities && props.averageTime && <>
      <Graphs tickets={props.tickets} statuses={props.statuses} priorities={props.priorities} averageTime={props.averageTime}/>
    </>}
  {props.statuses && props.priorities && props.customers && props.users && props.teams && <>
    <TicketList tickets={props.tickets ?? []} reload={() => props.reload} statuses={props.statuses} priorities={props.priorities} customers={props.customers} users={props.users} teams={props.teams} />
  </>}
  </div>
}

const CrmDashboard: FC<{customers: Customer[], reload: () => void, deals: Deal[], me: User}> = (props) =>  {
  const {tenant} = useTenant()
  return <>
    <NewestCustomers customers={props.customers} reload={props.reload} tenant={tenant ?? ''}/>
    <DashDeals tenant={tenant ?? ''} deals={props.deals} me={props.me} />
  </>
}

const NewestCustomers: FC<{customers: Customer[], reload: () => void, tenant: string}> = (props) => {
  const navigate = useNavigate()
  const filteredCustomers = props.customers.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1).slice(0, 5);
  return <div className={"mb-12"}>
    <h1 className={"text-2xl mt-2 mb-3"}>CRM</h1>
    <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Recente klanten</p>
    <DataTable
      keyProperty={'id'}
      data={filteredCustomers}
      columns={[
        {
          header: 'Naam',
          property: 'name'
        },
        {
          header: 'Tags',
          property: 'name',
          transform: (_, customer) => {
            if (customer.tags.length === 0) {
              return <div>-</div>
            }
            return <div className={"flex flex-wrap gap-1"}>
              {customer.tags.map(tagCustomer => <TagPill tag={tagCustomer.tag!} size={'sm'} />)}
            </div>;
          }
        },
        {
          header: 'E-mail',
          property: 'email'
        },
        {
          header: 'Telefoon',
          property: 'phone'
        },
        {
          header: 'Contactpersoon',
          property: 'contact_person'
        },
      ]}
      placeholder={<>Nog geen relaties</>}
      onClickRow={(customer) => navigate(`/${props.tenant}/relations/${customer.id}`)}
    />
  </div>
}

const DashDeals: FC<{deals: Deal[], tenant: string, me: User}> = (props) => {
  const filteredDeals = props.deals.filter(d => d.user?.id === props.me.id)
  return <>
    <h1 className={"text-2xl "}>Jouw deals</h1>
    {filteredDeals ? <DealDashboard deals={filteredDeals} tenant={props.tenant} showClosed={false}/> : <Skeleton type={"card"} />}
  </>
}

const Graphs: FC<{ tickets: Ticket[], statuses: TicketStatus[], priorities: TicketPriority[], averageTime: AverageTime}> = (props) => {
  const statusCounts = getStatusCounts(props.tickets, props.statuses)
  const priorityCount = getPriorityCounts(props.tickets, props.priorities)
  const [pieType, setPieType] = usePersistentState('pieStatus', 'status')
  return <div className={"flex flex-row gap-2 mb-8"}>
    <div
      className={"basis-3/5 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 h-64 w-max rounded grid grid-cols-1 lg:grid-cols-2 p-2 dark:text-brand-300"}>
      <div className={"flex flex-col justify-center items-center"}>
        <h2 className={"font-medium text-sm"}>Totale tijd:</h2>
        <span className={"text-xl font-bold mt-1 text-brand-900 dark:text-brand-300"}>{props.averageTime.totalTime}</span>
      </div>
      <div className={"flex flex-col justify-center items-center"}>
        <h2 className={"font-medium text-sm"}>Aantal ticket met tijd:</h2>
        <span className={"text-xl font-bold mt-1 text-brand-900 dark:text-brand-300"}>{props.averageTime.ticketCount}</span>
      </div>
      <div className={"flex flex-col justify-center items-center"}>
        <h2 className={"font-medium text-sm"}>Gemiddelde afhandel tijd:</h2>
        <span className={"text-xl font-bold mt-1 text-brand-900 dark:text-brand-300"}>{Math.round(props.averageTime.averageTime * 100) / 100} min.</span>
      </div>
    </div>
    <div className={"basis-2/5 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 h-80 w-max rounded flex flex-col justify-center items-center"}>
      <div className={'flex flex-col justify-start! items-start!'}>
        {/*// @ts-ignore*/}
        <Select label={''} options={{'status': 'Status', 'priority': 'Priority'}} value={pieType} onChange={(s) => setPieType(s)} />
      </div>
          <Piechart data={pieType === 'status' ? statusCounts : priorityCount} cx={110} cy={150} innerRadius={60} outerRadius={90} labelText={"Tickets"} labelValue={String(props.tickets.length)} />
    </div>
  </div>
}


const TicketList: FC<{ tickets: Ticket[], reload: () => void, statuses: TicketStatus[], customers: Customer[], users: User[], priorities: TicketPriority[], teams: Team[]}> = props => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const filteredTickets = props.tickets.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1).slice(0, 5);

  return <div>
    <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Nieuwe tickets</p>
    {filteredTickets.map((ticket, i) => <TicketListCard
      key={i}
      ticket={ticket}
      isFirst={i===0}
      isLast={i===(filteredTickets.length - 1)}
      team={props.teams.find(team => team.id === ticket.team_id) ?? null}
      customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
      status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
      priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
      assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
    />)}
  </div>
}

const getStatusCounts = (tickets: Ticket[], statuses: TicketStatus[]): { name: string; count: number }[] => {
  const countMap: { [statusId: string]: number } = {};
  tickets.forEach(ticket => {
    countMap[ticket.status_id] = (countMap[ticket.status_id] || 0) + 1;
  });

  const countsArray = Object.entries(countMap).map(([statusId, count]) => ({
    name: statusId,
    count: count,
  }));

  countsArray.forEach((item, index) => {
    const status = statuses.find(status => status.id === item.name);
    if (status) {
      countsArray[index].name = status.status;
    }
  });

  return countsArray;
};

const getPriorityCounts = (tickets: Ticket[], priorities: TicketPriority[]): { name: string; count: number }[] => {
  const countMap: { [statusId: string]: number } = {};
  tickets.forEach(ticket => {
    countMap[ticket.priority_id] = (countMap[ticket.priority_id] || 0) + 1;
  });

  const countsArray = Object.entries(countMap).map(([priorityId, count]) => ({
    name: priorityId,
    count: count,
  }));

  countsArray.forEach((item, index) => {
    const priority = priorities.find(priority => priority.id === item.name);
    if (priority) {
      countsArray[index].name = priority.priority;
    }
  });

  return countsArray;
};