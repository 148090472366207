import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Team, TicketPriority} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";

export const DeleteTeamsModal: FC<{team: Team, tenant: string}> = (props) => {
  const {deleteTeam} = useApiCall()
  const reload = useRefresh()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteTeam(props.team.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`${props.tenant}/ticketflow`)
  }

  return <>
    <p>
      <strong>LET OP!</strong> je probeert nu <strong>{props.team.team_name}</strong> uit je omgeving te verwijderen.
    </p>
    <InputErrors errors={errors} field={'error'} />
      <ModalFooter icon={faTrash} text={"delete"} onSubmit={confirm}/>
  </>
}