import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Team, TicketPriority} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";

export const EditTeamsModal: FC<{team: Team, tenant: string}> = (props) => {
  const [teamName, setTeamName] = useState(props.team.team_name)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editTeam} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editTeam(props.team.id, teamName).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [teamName])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"items-center space-x-4"}>
        <Input label={"Status"} type={"text"} value={teamName} onChange={(v) => setTeamName(v)}/>
        <InputErrors errors={errors} field={'priority'} />
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}