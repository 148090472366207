import React, {FC} from "react";
import {Ticket} from "../../../api/dto";
import {useRefresh} from "../../RefreshController";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../form/Button";

export const HelpAssignUser: FC<{ticket: Ticket, tenant: string}> = (props) => {
  const reload = useRefresh()
  const navigate = useNavigate();

  return <div className={"flex "}>
    <div className={"flex-1 my-4 rounded border border-amber-400 dark:border-amber-700 bg-amber-50 dark:bg-zinc-700 min-h-14"}>
      <div className={"flex space-x-4 items-center justify-between px-4 py-3"}>
        <div className={"flex-1 text-amber-900 dark:text-amber-500"}>
          <div className={'flex items-center font-bold'}>
            <FontAwesomeIcon icon={faWarning} bounce={true} className={"mr-3 h-4 w-4"} />
            <p>User kon niet gekoppeld worden</p>
          </div>
          <div className={'ml-7 text-sm font-medium mt-1'}>
            <p>Dit ticket kon niet automatisch aan een gebruiker gekoppeld worden met het volgende e-mail adress: <span className={"font-bold underline"}>({props.ticket.user_email})</span> </p>
            <p>Koppel deze handmatig op de klant pagina.</p>
          </div>
        </div>
        <div>
          {!props.ticket.customer_id ? <Button type={"primary"} size={"md"} text={"Koppel eerst een klant"} disabled={true}/>
            : <Button type={"primary"} size={"md"} text={"Naar klant pagina"} icon={faChevronRight} onClick={() => navigate(`/${props.tenant}/customers/${props.ticket.customer_id}`)}/>}
        </div>
      </div>
    </div>
  </div>
}