import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Ticket, TicketReply} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";

export const EditDescriptionModal: FC<{ticket: Ticket}> = (props) => {
  const [description, setDescription] = useState(props.ticket.description)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editTicket} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editTicket(
      props.ticket.id,{
      description: description,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [description])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"items-center space-x-4"}>
        <textarea className={"min-w-full h-40 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-600 rounded px-2 py-1"} value={description} onChange={(event) => setDescription(event.target.value)}></textarea>
        <InputErrors errors={errors} field={'priority'} />
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}