import {FC} from "react";
import {CMListSubscriber, Customer, Tag} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faInbox, faUser} from "@fortawesome/free-solid-svg-icons";
import {SimplePaginate} from "../components/data/SimplePaginate";

interface MixedArray {
  name: string;
  email:string;
  status: 'Abonnee'|'Afgemeld'|'Handmatig';
}

export const CMListSubscribers: FC<{list: string, subscribers: CMListSubscriber[], tenantTags: Tag[], customers: Customer[]}> = (props) => {
  const subscriberEmailSet = new Set(props.subscribers.map(sub => sub.email));
  const customerEmailSet = new Set(props.customers.map(customer => customer.email));
  const mixedArray: { name: string; email: string; status: string; }[] = [];

// Process customers
  props.customers.forEach(customer => {
    mixedArray.push({
      name: customer.name,
      email: customer.email,
      status: subscriberEmailSet.has(customer.email) ? 'Abonnee' : 'Afgemeld'
    });
  });

// Process subscribers who are not in customers
  props.subscribers.forEach(subscriber => {
    if (!customerEmailSet.has(subscriber.email)) {
      mixedArray.push({
        name: subscriber.name,
        email: subscriber.email,
        status: 'Handmatig'
      });
    }
  });

  const getStatusBorderClass = (status: string) => {
    switch (status) {
      case 'Handmatig':
        return 'border-blue-400 bg-blue-200 dark:bg-blue-400 dark:border-blue-600 dark:bg-blue-600';
      case 'Afgemeld':
        return 'border-orange-400 bg-orange-200 dark:border-orange-600 dark:bg-orange-600';
      default:
        return 'border-black bg-slate-200 dark:bg-black';
    }
  };
  return <div className={"pb-4"}>
    <div className={"flex justify-start items-center mb-2 font-bold border-b dark:border-zinc-600"}>
      <div className={"flex basis-1/5 space-x-2 justify-start items-center"}>
        <FontAwesomeIcon icon={faUser}/>
        <h1>Naam </h1>
      </div>
      <div className={"flex basis-3/5 space-x-2 justify-start items-center"}>
        <FontAwesomeIcon icon={faEnvelope}/>
        <h1>E-mail</h1>
      </div>
      <div className={"basis-1/5"}></div>
    </div>
    <SimplePaginate data={mixedArray} paginationLimit={10} resultsBuilder={(data) => {
      return <>{data.map((item, i) => {
        return <div key={i} className={"flex px-1 py-2 border-b border-gray-200 dark:border-zinc-600"}>
          <div className={"flex basis-1/5 space-x-2 justify-start items-center"}>
            <h1 className={""}>{item.name}</h1>
          </div>
          <div className={"flex basis-3/5 space-x-2 justify-start items-center"}>
            <h1 className={""}>{item.email}</h1>
          </div>
          <h1 className={`flex px-2 py-1 rounded-full text-xs border ${getStatusBorderClass(item.status)}`}>{item.status}</h1>
        </div>
      })}</>
    }} />
  </div>
}