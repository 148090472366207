import {Ticket, TicketTask, User} from "../../../api/dto";
import React, {FC, useEffect, useState} from "react";
import {useRefresh, useRefreshEffect} from "../../RefreshController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {
  faCheck,
  faCircleNotch,
  faClock,
  faPencil,
  faPlusCircle,
  faSave,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {Button} from "../../form/Button";
import {useApi, useFetchedResource} from "../../../api/APIContext";
import {useApiCall} from "../../../api/api";
import {TagPill} from "../../content/TagPill";

export const TicketTasksTab: FC<{ticket: Ticket, tenantUsers: User[]}> = ({ticket, tenantUsers}) => {
  const {getUserMe} = useApiCall()
  const {resource: user, reload: reloadUser} = useFetchedResource(() => getUserMe());
  const [tasks, setTasks] = React.useState(ticket.tasks)
  useRefreshEffect(() => {
    reloadUser(undefined)
  })
  useEffect(() => {
    setTasks(ticket.tasks)
  }, [ticket.tasks]);
  const setChecked = async (taskId: string, checked: boolean) => {
    setTasks(tasks.map(t => t.id === taskId ? {...t, done: checked} : t))
    // TODO: Save to API.
  }

  const orderedTasks = ticket.tasks
    .filter(task => task.description.toLowerCase())
    .sort((a, b) => {
      const aChecked = ticket.tasks.some(t => t?.id === a.id)
      const bChecked = ticket.tasks.some(t => t?.id === b.id)
      if (aChecked && !bChecked) return 1
      if (!aChecked && bChecked) return -1
      return a.description.localeCompare(b.description);
    })
  return <div className={'bg-white dark:bg-zinc-700 rounded border border-slate-300 dark:border-zinc-500 px-3 py-2 flex flex-col'}>
    {tasks.length > 0 ?

      //list of tasks
      <>
    {orderedTasks.map((task) => {
      return <TaskEditorRow task={task} ticket={ticket} key={task.id} />
    })}
      </>
      //if no list of tasks
      :
      <div className={'mb-2 h-20 flex flex-col items-center justify-center opacity-60 text-sm'}>
        <FontAwesomeIcon icon={faPlusCircle} className={"mb-3"}/>
        <span>Dit ticket heeft geen taken</span>
      </div>}
    {user && <AddTaskForm ticket={ticket} user={user} tenantUsers={tenantUsers}/>}
  </div>
}

const TaskEditorRow: FC<{ task:TicketTask, ticket: Ticket }> = (props) => {
  const { checkTicketTask, uncheckTicketTask, deleteTicketTask } = useApiCall()
  const [checked, setChecked] = useState(props.task.is_done)
  const refresh = useRefresh()
  const toggleTask = async () => {
    setChecked(!checked)
    if (checked) {
      await uncheckTicketTask(props.ticket.id, props.task.id)
    } else {
      await checkTicketTask(props.ticket.id, props.task.id)
    }
    refresh()
  }
  const deleteTask = async () => {
    await deleteTicketTask(props.ticket.id, props.task.id)
    refresh()
  }
  useEffect(() => {
    setChecked(props.task.is_done)
  }, [props.task]);
  return <div className={"rounded hover:bg-slate-50 dark:hover:bg-zinc-600 group cursor-pointer flex items-stretch min-h-12 py-2 -mx-3 px-3"}>
    <div className={"flex items-center flex-1"} onClick={() => toggleTask()}>
      <div className={`h-6 w-6 rounded-full border border-slate-200 mr-3 flex items-center justify-center ${checked ? 'bg-brand-800 border-brand-800 dark:bg-brand-500 dark:border-brand-500' : 'border-slate-200 dark:border-slate-500'}`}>
        <FontAwesomeIcon icon={faCheck} className={checked ? 'text-white dark:text-zinc-950' : "text-transparent group-hover:text-slate-300 dark:group-hover:text-slate-500"}/>
      </div>
      <div className={`flex-1 pr-4`}>
        <h1>{props.task.description}</h1>
      </div>
    </div>
    <div className={'flex items-center mr-2'}>
      <span className={"border border-slate-200 dark:border-zinc-600 dark:bg-zinc-600 rounded px-4 py-1"}>{props.task.user_name}</span>
    </div>
    <div className={"flex items-center justify-end w-32"}>
      <div
        onClick={() => deleteTask()}
        className={"h-6 w-6 rounded-full hover:bg-red-200 dark:hover:bg-red-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-red-600 dark:hover:text-red-50"}>
        <FontAwesomeIcon icon={faTimes} className={"h-3"}/>
      </div>
    </div>
  </div>
}

const AddTaskForm: FC<{ ticket: Ticket, tenantUsers: User[], user: User}> = ({ticket, tenantUsers, user}) => {
  const refresh = useRefresh()
  const {addTicketTask} = useApiCall()
  const [text, setText] = React.useState('')
  const [assignee, setAssignee] = React.useState(user.id)
  const refactoredTenantUsers = tenantUsers.map((item) => [item.id, item.name])
  const selectedAssignee = tenantUsers.find(u => u.id === assignee)
  const [saving, setSaving] = React.useState(false)

  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    setSaving(true)
    await addTicketTask(ticket.id, assignee, text)
    setText('')
    setSaving(false)
    refresh()
  }
  return <form onSubmit={submit} className={"border-t border-slate-300 -mx-3"}>
    <label className={"flex items-stretch cursor-text -mx-[1px] border border-transparent rounded -mt-[1px] -mb-[calc(0.5rem+1px)] h-12 focus-within:border-brand-600"}>
      <div className={"flex items-center"}>
        <FontAwesomeIcon icon={saving ? faCircleNotch : faPlusCircle} spin={saving} className={"text-brand-800 dark:text-brand-600 mx-4"} />
      </div>
      <input type="text" value={text} onChange={e => setText(e.target.value)} className={"flex-1 bg-transparent outline-none rounded"} placeholder={'Nieuwe taak...'} />
      <div className={"flex items-center mx-2"}>
        <IconDropdownButton type={"secondary"} size={"sm"} text={selectedAssignee?.name ?? '-'} icon={faUser}
                            data={Object.fromEntries(refactoredTenantUsers)} onClick={async (key) => {
          setAssignee(key)
        }}/>
      </div>
      <div className={"flex items-center justify-end mx-2 w-32"}>
        <Button type={'primary'} disabled={text.trim().length === 0} size={'sm'} text={'Toevoegen'} icon={faSave} submit />
      </div>
    </label>
  </form>
}