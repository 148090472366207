import {FC} from "react";
import {Button} from "../components/form/Button";
import { Paragraph } from "../components/Paragraph";
import {PageHeader} from "../components/content/PageHeader";
import {faArrowRightFromBracket, faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import keycloak from "../auth/keycloak";


export const Forbidden: FC = () => {
  return <div>
    <PageHeader><FontAwesomeIcon icon={faMinusCircle} className={'mr-3 text-red-800'} />Geen toegang</PageHeader>
    <Paragraph>
      Je hebt geen toegang tot deze pagina. Neem contact op met de beheerder van de applicatie als je denkt dat dit een fout is.
    </Paragraph>
    <div className={'mt-8'}>
      <Button type={"primary"} size={"md"} text={"Uitloggen"} icon={faArrowRightFromBracket} onClick={() => keycloak.logout({redirectUri: 'https://windesk.nl'})} />
    </div>
  </div>
}