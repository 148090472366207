import {FC} from "react";
import {Paragraph} from "../Paragraph";
import {Card} from "../Card";
import {useKeycloak} from "@react-keycloak/web";

export const UnauthenticatedPlaceholder: FC = () => {
  const {keycloak} = useKeycloak()
  return <div className={'bg-slate-900 text-white min-h-screen flex flex-col items-center justify-center'}>
    <div className={"max-w-md"}>
      <Card title={"Inloggen vereist."}>
        <Paragraph>Om de applicatie te gebruiken moet je ingelogd zijn. Je wordt automatisch doorverwezen. Als dit niet gebeurt kun je ook op 'inloggen' klikken.</Paragraph>
        <button className={"bg-slate-900 text-white font-medium rounded px-2 py-2 w-full"} onClick={() => keycloak.login()}>Inloggen</button>
      </Card>
    </div>
  </div>
}
