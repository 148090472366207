import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {CMListTag} from "../api/dto";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";

export const DeleteCMListTagModal: FC<{tag: CMListTag}> = (props) => {
  const {deleteCampaignMonitorListsTags} = useApiCall()
  const reload = useRefresh()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteCampaignMonitorListsTags(props.tag.listId, props.tag.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }

  return <>
    <p>
      <strong>Let op!</strong> Weet je zeker dat je de tag <strong>{props.tag.tag.name}</strong> wilt ontkoppelen van deze lijst?
    </p>
    <InputErrors errors={errors} field={'error'} />
    <ModalFooter icon={faTrash} text={"Ontkoppelen"} onSubmit={confirm}/>
  </>
}