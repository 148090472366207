import {FC, useState} from "react";
import {Customer, SlaRule, Team, Ticket, TicketPriority, TicketStatus, User} from "../../api/dto";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {faBuilding, faCheckCircle, faClock, faFlag, faSquarePlus, faUser} from "@fortawesome/free-regular-svg-icons";
import {humanTimeDiff} from "../../util/humanTimeDiff";
import {CustomerAvatar} from "../content/CustomerAvatar";
export const TicketListCard: FC<{ticket: Ticket, customer: Customer|null, status: TicketStatus|null, priority: TicketPriority|null, assignee: User|null, team: Team|null, isFirst: boolean, isLast: boolean}> = (props) => {
  const navigate = useNavigate()
  const {tenant} = useTenant()

  // get sla + rules

  // calculate deadline time

  // calculate diff between now and deadline time

  const borderStyle = `border dark:border-zinc-500 ${props.isLast ? 'rounded-b' : 'border-b-0'} ${props.isFirst ? 'rounded-t' : ''}`
  const [selected, setSelected] = useState<boolean>(false)
  const toTicket = () => navigate(`/${tenant}/ticket/${props.ticket.id}`)

  const closeToDeadline = props.ticket.sla_deadline !== null && (props.ticket.sla_deadline.getTime() - (new Date).getTime()) < (1000*60*120) // Een uur.
  const pastDeadline = props.ticket.sla_deadline !== null && (props.ticket.sla_deadline.getTime() - (new Date).getTime()) < 0

  return <div className={`${borderStyle} ${selected ? 'bg-brand-50 dark:bg-zinc-600' : 'bg-white dark:bg-zinc-700 hover:bg-slate-50 hover:dark:bg-zinc-600'} cursor-pointer flex items-stretch`}>
    <div className={"flex items-center justify-center"}>
      <label className={"flex items-center justify-center m-2 w-10 h-10 border border-transparent hover:border-brand-500 hover:dark:border-brand-600 hover:bg-brand-100 hover:dark:bg-zinc-500 rounded-lg cursor-pointer"}>
        <input type="checkbox" className={"cursor-pointer accent-brand-700"} checked={selected} onChange={() => setSelected(old => !old)} />
      </label>
    </div>
    <div className={"pr-4 flex items-center justify-center"} onClick={() => toTicket()}>
      {props.customer ? <CustomerAvatar customer={props.customer}/> : <UnassignedTicketCustomerAvatar />}
    </div>
    <div className={"flex-1 py-3 "} onClick={() => toTicket()}>
      {(props.priority || props.status) && <div className={'mb-2'}>
        {props.status && <TicketStatusLabel status={props.status} />}
        {props.priority && <TicketPriorityLabel priority={props.priority} />}
      </div>}
      <div className={""}>
        <span className={"font-medium"}>{props.ticket.subject}</span>
        <span className={"text-sm text-gray-500 dark:text-zinc-300 font-medium ml-2 whitespace-nowrap"}>#{props.ticket.number}</span>
      </div>
      <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 text-xs mt-3 text-slate-500 dark:text-zinc-300'}>
        {props.customer && <div className={'flex items-center space-x-2'}>
          <FontAwesomeIcon icon={faBuilding} />
          <span>{props.customer.name}</span>
        </div>}
        <div className={`flex items-center space-x-2 ${!props.assignee ? 'text-red-700 dark:text-red-600' : ''}`}>
          <FontAwesomeIcon icon={faUser} />
          <span>{props.assignee?.name ?? '-'}</span>
        </div>
        {props.customer && <div className={'flex items-center space-x-2'}>
          <FontAwesomeIcon icon={faUserGroup} />
          <span>{props.team?.team_name ?? '-'}</span>
        </div>}

        {props.ticket.closedAt !== null ? <>{props.ticket.closedAt < props.ticket.sla_deadline! ? "Behaald" : "Overschreden" }</> :
          <>
            <div className={'flex items-center'}>
              <div
                className={`flex items-center space-x-2 ${pastDeadline ? 'font-bold bg-red-600 text-white uppercase py-1 px-2 -my-1 rounded' : (closeToDeadline ? 'font-bold text-amber-600' : '')}`}>
                <FontAwesomeIcon icon={faClock}/>
                {props.ticket.sla_deadline ? <span>{humanTimeDiff(props.ticket.sla_deadline)}</span> :
                  <span>-:--</span>}
              </div>
            </div>
          </>}
        <div className={'flex items-center space-x-2'}>
          <FontAwesomeIcon icon={faSquarePlus}/>
          <span>{humanTimeDiff(props.ticket.createdAt)}</span>
        </div>
      </div>
    </div>
    <div className={" px-4 flex items-center justify-center"} onClick={() => toTicket()}>
      <FontAwesomeIcon icon={faChevronRight} className={'text-brand-500 dark:text-brand-600'}/>
    </div>
  </div>
}

const UnassignedTicketCustomerAvatar: FC = () => {
  return <div className={"flex items-center justify-center h-10 w-10 text-sm font-medium text-brand-600 dark:text-brand-200 bg-brand-100 dark:bg-brand-700 border border-brand-100 dark:border-brand-800 rounded my-2"}>
    ??
  </div>
}

export const TicketStatusLabel: FC<{status: TicketStatus}> = (props) => {
  const style = props.status.isClosed ? 'border-emerald-300 dark:border-emerald-500 bg-emerald-50 dark:bg-emerald-600 text-emerald-700 dark:text-emerald-100' : (props.status.isOnHold ? 'border-amber-300 dark:border-amber-500 bg-amber-50 dark:bg-amber-600 text-amber-700 dark:text-amber-100' : 'border-blue-300 dark:border-blue-500 bg-blue-50 dark:bg-blue-600 text-blue-700 dark:text-blue-100')
  return <span className={`text-xs font-medium px-2 py-1 mr-2 rounded border ${style}`}><FontAwesomeIcon icon={faCheckCircle} className={"mr-1"} />{props.status.status}</span>
}
export const TicketPriorityLabel: FC<{priority: TicketPriority}> = (props) => {
  const style = [
    'border-red-600 bg-red-600 text-red-50',
    'border-amber-300 bg-amber-50 text-amber-700 dark:border-amber-400 dark:bg-amber-500 dark:text-amber-100',
    'border-lime-300 bg-lime-50 text-lime-700 dark:border-lime-400 dark:bg-lime-500 dark:text-lime-100',
    'border-cyan-300 bg-cyan-50 text-cyan-700 dark:border-cyan-400 dark:bg-cyan-500 dark:text-cyan-100',
  ][props.priority.order < 1 ? 0 : (props.priority.order - 1)] ?? 'border-sky-300 bg-sky-50 text-sky-700 dark:border-sky-400 dark:bg-sky-500 dark:text-sky-100'
  return <span className={`text-xs font-medium px-2 py-1 mr-2 rounded border ${style}`}><FontAwesomeIcon icon={faFlag} className={"mr-1"} />{props.priority.priority}</span>
}