import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {InputErrors} from "../components/form/InputErrors";
import {useFetchedResource} from "../api/APIContext";
import {Select} from "../components/form/Select";
import {CMListTag, Tag} from "../api/dto";
import {PillSelect} from "../components/form/PillSelect";

export const AddCMListTagModal: FC<{list: string, tags: Tag[], currentTags: CMListTag[]}> = (props) => {
  const { updateCampaignMonitorListsTags } = useApiCall()
  const refactoredTags = props.tags.map((item) => [item.id, item.name])
  const [selectedTag, setSelectedTag] = useState(props.tags[0].id)
  const [errors, setErrors] = useState<ErrorBag>({})
  const reload = useRefresh()
  const save = useCallback( async () => {
    const deployment = await updateCampaignMonitorListsTags(
      props.list, selectedTag).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [selectedTag])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      {/*@ts-ignore*/}
      <div className={"items-center space-x-4"}>
        <Select label={"Tag"} options={Object.fromEntries(refactoredTags)} value={selectedTag}
                onChange={(v) => setSelectedTag(v)}/>
        <InputErrors errors={errors} field={'tag'}/>
      </div>
    </form>
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </>
}