import React, {FC, useCallback, useState} from "react";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Customer} from "../api/dto";

export const AddCustomerUserModal: FC<{customer: Customer}> = (props) => {
  const {addCustomerUser} = useApiCall()
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh();

  const save = useCallback(async () => {
    const deployment = await addCustomerUser(
      props.customer.id,{
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [first_name, last_name, email, phone])
  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
        <div className={"flex items-center space-x-4"}>
          <div className={"basis-1/2"}>
            <Input label={"Voornaam"} required={true} type={"text"} value={first_name} placeholder={"Voornaam"} onChange={(v) => setFirstName(v)}/>
            <InputErrors errors={errors} field={'firstName'}/>
          </div>

          <div className={"basis-1/2"}>
            <Input label={"Achternaam"} required={true} type={"text"} value={last_name} placeholder={"Achternaam"} onChange={(v) => setLastName(v)}/>
            <InputErrors errors={errors} field={'lastName'}/>
          </div>
        </div>


      <div className={"items-center space-x-4"}>
        <Input label={"email"} required={true} type={"text"} value={email} placeholder={"E-mail"} onChange={(v) => setEmail(v)}/>
        <InputErrors errors={errors} field={'email'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Input label={"Telefoon"} required={false} type={"text"} value={phone} placeholder={"Telefoon nummer"}
               onChange={(v) => setPhone(v)}/>
        <InputErrors errors={errors} field={'phone'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}