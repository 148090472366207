import {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faChartLine, faFilter} from "@fortawesome/free-solid-svg-icons";

export const Filters: FC = () => {

  return (
    <ContentContainer>
      <PageIconHeader icon={faFilter}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Filters"} />
        <PageHeader>Filters</PageHeader>
      </PageIconHeader>
    </ContentContainer>
  )
}