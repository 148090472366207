import {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faBan, faCheck, faChevronRight, faGear, faTrash, faX} from "@fortawesome/free-solid-svg-icons";
import {Callout} from "../components/content/Callout";
import {useModal} from "../components/layout/ModalProvider";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button, IconButton} from "../components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {Team, TicketPriority, TicketStatus} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {useRefreshEffect} from "../components/RefreshController";
import {AddStatusModal} from "../modals/AddStatusModal";
import {EditStatusModal} from "../modals/EditStatusModal";
import {DeleteStatusModal} from "../modals/DeleteStatusModal";
import {AddPriorityModal} from "../modals/AddPriorityModal";
import {EditPriorityModal} from "../modals/EditPriorityModal";
import {DeletePriorityModal} from "../modals/DeletePriorityModal";
import {SectionDivider} from "../components/content/SectionDivider";
import {EditTeamsModal} from "../modals/EditTeamsModal";
import {DeleteTeamsModal} from "../modals/DeleteTeamsModal";
import {AddTeamModal} from "../modals/AddTeamModal";

export const Ticketflows: FC = () => {

  const {tenant} = useParams();
  const {getAllStatuses, getAllPriorities, getTeams} = useApiCall()
  const {resource: statusResource, loading: loadingStatus, reload: reloadStatus} = useFetchedResource(() => getAllStatuses())
  const {resource: priorityResource, loading: loadingPriority, reload: reloadPriority} = useFetchedResource(() => getAllPriorities())
  const {resource: teamsResource, loading: loadingTeams, reload: reloadTeams} = useFetchedResource(() => getTeams())

  useRefreshEffect(() => {
    if (tenant === undefined) {
      console.warn('Tenant is undefined')
      return
    }
    reloadTeams(undefined);
    reloadStatus(undefined);
    reloadPriority(undefined);
  });

  return (
    <ContentContainer>
      <PageIconHeader icon={faGear}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
        ]} currentPage={"Instellingen"} />
        <PageHeader>Ticketflows</PageHeader>
      </PageIconHeader>

      <AddStatusForm />
      {statusResource && <>
        <StatusTable statuses={statusResource}/>
      </>}

      <SectionDivider/>

      <AddPriorityForm />
      {priorityResource && <>
        <PriorityTable priorities={priorityResource}/>
      </>}

      <SectionDivider/>

      <AddTeamForm />
      {teamsResource &&
        <TeamsTable teams={teamsResource} />
      }

    </ContentContainer>
  )
}

const AddStatusForm: FC = () => {
  const {open: openCreateModal} = useModal({title: 'Status toevoegen', body: <AddStatusModal />})
  return <Callout>
    <SectionHeader>Voeg een nieuwe status toe</SectionHeader>
    <Paragraph>
      Maak een nieuwe status aan overzichtelijker je tickets bij te houden.
    </Paragraph>
    <Button type={"primary"} size={"sm"} text={"Status toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
  </Callout>
}

const StatusTable: FC<{statuses: TicketStatus[]}> = props => {
  return <>
    <div className="my-4 align-middle inline-block min-w-full">
      <div className={"overflow-hidden border-2 border-slate-100 dark:border-zinc-600 sm:rounded-lg bg-white dark:bg-zinc-700"}>
        <table className={"min-w-full"}>
          <thead className={"border-b border-slate-200 dark:border-zinc-600 p-2"}>
          <tr>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Status</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Closed</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>On-Hold</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Default</th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
          </tr>
          </thead>
          <tbody>
          {props.statuses.map((prop, i) => {
            return <StatusTableRow status={prop} key={i} />
          })}
          </tbody>
        </table>
      </div>
    </div>
  </>
}
const StatusTableRow: FC<{status: TicketStatus}> = props => {
  const {tenant} = useParams()
  const {open: openCreateModal} = useModal({title: `Edit ${props.status.status}`, body: <EditStatusModal status={props.status} tenant={tenant!} />})
  const {open: openDeleteModal} = useModal({title: `Delete ${props.status.status}?`, body: <DeleteStatusModal status={props.status} tenant={tenant!} />})
  return <>
    <tr>
      <td className={"py-3 px-4 text-left"}>{props.status.status}</td>
      <td className={`py-3 px-4 text-left ${props.status.isClosed ? 'text-green-500' : 'text-red-500'}`}>{props.status.isClosed ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faX}/>}</td>
      <td className={`py-3 px-4 text-left ${props.status.isOnHold ? 'text-green-500' : 'text-red-500'}`}>{props.status.isOnHold ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faX}/>}</td>
      <td className={`py-3 px-4 text-left ${props.status.isDefault ? 'text-green-500' : 'text-red-500'}`}>{props.status.isDefault ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faX}/>}</td>
      <td className={""}><IconButton icon={faPenToSquare} size={"sm"} type={"secondary"} onClick={openCreateModal}/></td>
      <td className={"pr-3"}><IconButton icon={faTrash} size={"sm"} type={"danger"} onClick={openDeleteModal}/></td>
    </tr>
  </>
}







const AddPriorityForm: FC = () => {
  const {open: openCreateModal} = useModal({title: 'Prioriteit toevoegen', body: <AddPriorityModal />})
  return <Callout>
    <SectionHeader>Voeg een nieuwe prioriteit toe</SectionHeader>
    <Paragraph>
      Maak een nieuwe Prioriteit aan overzichtelijker je tickets bij te houden.
    </Paragraph>
    <Button type={"primary"} size={"sm"} text={"Prioriteit toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
  </Callout>
}

const PriorityTable: FC<{priorities: TicketPriority[]}> = props => {
  return <>
    <div className="my-4 align-middle inline-block min-w-full">
      <div className={"overflow-hidden border-2 border-slate-100 dark:border-zinc-600 sm:rounded-lg bg-white dark:bg-zinc-700"}>
        <table className={"min-w-full"}>
          <thead className={"border-b border-slate-200 dark:border-zinc-600 p-2"}>
          <tr>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Priority</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Order</th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
          </tr>
          </thead>
          <tbody>
          {props.priorities.map((prop, i) => {
            return <PriorityTableRow priority={prop} key={i} />
          })}
          </tbody>
        </table>
      </div>
    </div>
  </>
}
const PriorityTableRow: FC<{priority: TicketPriority}> = props => {
  const {tenant} = useParams()
  const {open: openCreateModal} = useModal({title: `Edit ${props.priority.priority}`, body: <EditPriorityModal priority={props.priority} tenant={tenant!} />, size: "md"})
  const {open: openDeleteModal} = useModal({title: `Delete ${props.priority.priority}?`, body: <DeletePriorityModal priority={props.priority} tenant={tenant!} />, size: "md"})
  return <>
    <tr>
      <td className={"py-3 px-4 text-left"}>{props.priority.priority}</td>
      <td className={"py-3 px-4 text-left"}>{props.priority.order}</td>
      <td className={""}><IconButton icon={faPenToSquare} size={"sm"} type={"secondary"} onClick={openCreateModal}/></td>
      <td className={"pr-3"}><IconButton icon={faTrash} size={"sm"} type={"danger"} onClick={openDeleteModal}/></td>
    </tr>
  </>
}


const AddTeamForm: FC = () => {
  const {open: openCreateModal} = useModal({title: 'Team toevoegen', body: <AddTeamModal />})
  return <Callout>
    <SectionHeader>Voeg een nieuw team toe</SectionHeader>
    <Paragraph>
      Maak een nieuwe teams aan waaraan specifieke tickets toegewezen kunnen worden
    </Paragraph>
    <Button type={"primary"} size={"sm"} text={"Team toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
  </Callout>
}

const TeamsTable: FC<{teams: Team[]}> = props => {
  return <>
    <div className="my-4 align-middle inline-block min-w-full">
      <div className={"overflow-hidden border-2 border-slate-100 dark:border-zinc-600 sm:rounded-lg bg-white dark:bg-zinc-700"}>
        <table className={"min-w-full"}>
          <thead className={"border-b border-slate-200 dark:border-zinc-600 p-2"}>
          <tr>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Teams</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Aantal leden</th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
          </tr>
          </thead>
          <tbody>
          {props.teams.map((prop, i) => {
            return <TeamsTableRow teams={prop} key={i}/>
          })}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const TeamsTableRow: FC<{teams: Team}> = props => {
  const {tenant} = useParams()
  const navigate = useNavigate();
  return <>
    <tr className={"hover:bg-brand-100 hover:dark:bg-zinc-600 hover:cursor-pointer border border-slate-100 dark:border-zinc-600"} onClick={() => navigate(`/${tenant}/teams/${props.teams.id}`)}>
      <td className={"py-3 px-4 text-left"}>{props.teams.team_name}</td>
      <td className={"py-3 px-4 text-left"}>{props.teams.user_count}</td>
      <td className={"py-3 px-4 text-left"}><span className={"flex flex-row items-center justify-center text-sm text-brand-700"}>DETAILS <FontAwesomeIcon className={"mx-1"} icon={faChevronRight}/></span></td>
    </tr>
  </>
}