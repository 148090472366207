import {faChevronLeft, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FC, ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

export const PageIconHeader: FC<{icon: IconDefinition, backButton?: boolean, children: ReactNode}> = (props) => {
  const navigate = useNavigate()
  return <div className={`flex flex-row ${props.backButton ? "relative -left-20" : ""}`}>
  {props.backButton === true ? <>
      <div className={"flex items-center mb-8"}>
        <div className={"h-16 w-16 hover:cursor-pointer hover:bg-slate-100 hover:dark:bg-brand-700 text-brand-800 dark:text-brand-700 hover:dark:text-brand-200 rounded-lg mr-4 flex items-center justify-center"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} className={"text-2xl"}/>
        </div>
      </div>
    </> : <></>}

    <div className={"flex items-center mb-8"}>
      <div className={"h-16 w-16 bg-slate-100 dark:bg-brand-700 text-slate-700 dark:text-brand-200 rounded-lg mr-4 flex items-center justify-center"}>
        <FontAwesomeIcon icon={props.icon} className={"text-3xl"}/>
      </div>
      <div className={"flex flex-col justify-center -mb-6 pt-4"}>
        {props.children}
      </div>
    </div>
  </div>
}